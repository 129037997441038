<template>
  <v-container>
    <v-card>
      <v-card-text class="scrollAudit">
        <div v-if="showList && currentAgentId">
          <p class="title">Calls</p>
          <!-- <v-divider class="mr-5" /> -->
          <div v-for="item in calls" :key="item._id">
            <v-row align="center" justify="center" v-if="item.confSession">
              <v-col sm="4" cols="4" md="2" xl="2" lg="2" pa-2 class="text-center">
                <img
                  class="avatar"
                  :src="getInitialAvatar(item.confSession.users[0].email)"
                />
              </v-col>
              <v-col sm="8" cols="8" md="10" xl="10" lg="10" pa-2>
                <div class="float-right">
                  <v-btn icon color="signzy_blue" @click="showDetails(item)">
                    <v-icon>fas fa-chevron-right</v-icon>
                  </v-btn>
                </div>
                <span class="title">{{item.confSession.users[0].name}}</span>
                <br />
                <span class="subtitle-1">{{item.confSession.users[0].email}}</span>
                <div class="text-right">
                  <v-chip small color="green" text-color="white">Video Conference</v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-else>
              <v-col sm="4" cols="4" md="2" xl="2" lg="2" pa-2 class="text-center">
                <img class="avatar" :src="getInitialAvatar(item.input.callData.users[0].name)" />
              </v-col>
              <v-col sm="8" cols="8" md="10" xl="10" lg="10" pa-2>
                <div class="float-right">
                  <v-btn icon color="signzy_blue" @click="showDetails(item)">
                    <v-icon>fas fa-chevron-right</v-icon>
                  </v-btn>
                </div>
                <span class="title">{{item.input.callData.users[0].name}}</span>
                <br />
                <span class="subtitle-1">{{item.input.callData.users[0].email}}</span>
                <div class="text-right">
                  <v-chip small color="green" text-color="white">Video KYC</v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider color="signzy_color" class="ml-5 mr-5 mt-1 mb-1" />
          </div>
        </div>
        <div v-else-if="!showList && currentAgentId">
          <v-btn depressed color="white" @click="goBack()">
            <v-icon class="mr-2">fas fa-arrow-left</v-icon>back
          </v-btn>
          <ViewAudit :callData="callData" :status="status" />
        </div>
        <div v-else class="text-center">
          <p class="title">Please select any one VCIP Admin to see the Audit Calls!</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { end_points } from "@/config";
import ViewAudit from "./ViewAudit";
import signzyAvatar from "@/Plugins/SignzyAvatarGenerator.js";
export default {
  data: () => ({
    calls: [],
    showList: true,
    currentAgentId: "",
    callData: {}
  }),
  methods: {
    getInitialAvatar(name) {
      return signzyAvatar({ name });
    },
    goBack() {
      this.showList = true;
      this.callData = {};
    },
    showDetails(callData) {
      this.callData = callData;
      this.showList = false;
    },
    async getAllCalls() {
      this.calls = [];
      let data = (
        await axiosInstance.get(
          end_points.audited_call_backops(
            this.customerId,
            this.agentId,
            this.status
          )
        )
      ).data;
      data.forEach(item => {
        if ((item.input && item.input.callData) || item.confSession) {
          this.calls.push(item);
        }
      });
    }
  },
  watch: {
    currentAgentId: function() {
      console.log("got currentAgentId, pulling calls");
      if (this.currentAgentId != this.agentId || this.agentId) {
        this.getAllCalls();
        this.showList = true;
      }
    },
    agentId: function() {
      console.log("got agent id, changing the currentAgentId");
      this.currentAgentId = this.agentId;
    },
    isCallListVisible: function() {
      this.showList = this.isCallListVisible;
    }
  },
  components: {
    ViewAudit
  },
  props: ["customerId", "agentId", "isCallListVisible", "status"]
};
</script>

<style scoped>
.scrollAudit {
  overflow: scroll;
  height: 600px;
}
.avatar {
  border-radius: 50%;
  width: 80%;
}
</style>
<template>
  <v-app id="inspire">
    <v-navigation-drawer :mini-variant="mini" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <div v-if="$route.path.includes('/settings/')">
        <br>
        <v-row>
          <v-col cols="12" style="justify-content: center; align-items: center;display: flex;">
            <v-btn outlined color="primary" :disabled="disableAddflow" @click="toggleAddNewFlow = !toggleAddNewFlow "
              style="width: 75%;">
              Add Flow +
            </v-btn>

            <div class="text-center">
              <v-dialog v-model="dialog" width="40%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" style="margin-left: 10px">
                    fas fa-info-circle
                  </v-icon>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Multiple Flows
                  </v-card-title>
                  <br>
                  <v-card-text>
                    <p>Here you can add Multiple call configurations under one account.</p>
                    <p>Under this tab, you will be able to create a Separate flows with different set of questionnaires,
                      call configurations, admin forms, instructions all under one account.</p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12">
            <div v-if="toggleAddNewFlow">
              <v-row>
                <v-col cols="8">
                  <v-text-field style="margin-left: 10%" label="Add Flow" v-model="newFlow"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn style="margin-top: 20px" @click="createNewFlow(newFlow)" small>
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <!--global config-->
      <div v-if="$route.path.includes('/settings/')">
        <v-card
          v-if="!$store.getters.subAdminAccessControl || ($store.getters.subAdminAccessControl && $store.getters.subAdminAccessControl.assignedFlows.includes('global'))">
          <v-row>
            <v-col cols="12">
              <v-list-item link active-class="activeMenu" :class="$route.path.includes('global') ? 'activeMenu' : ''"
                @click="$router.push({ path: '/admin/control/settings/global-settings' })">
                Global Configurations
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <!--global config-->
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group v-model="isActive" v-else-if="item.children" :key="item.text" append-icon>
            <template v-slot:activator>
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-left">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link active-class="activeMenu"
              :class="$route.path.includes(child.path) ? 'activeMenu' : ''" @click="$router.push({ path: child.path })">
              <v-list-item-action v-if="child.icon">
                <v-icon :color="
                    $route.path.includes(child.path) ? 'app_bar_icon' : ''
                  ">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="text-right">
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.index"
            :class=" (!$route.path.includes('/settings/')) ? (item.path === $route.path ? 'activeMenu' : '') : ''"
            @click="(!$route.path.includes('/settings/')) ? (($route.path !== item.path)  ? $router.push({ path: item.path }) : '') : ''"
            :style="!(!$route.path.includes('/settings/')) ? 'padding:0px' : '' ">
            <template
              v-if="(item.flow && !$store.getters.subAdminAccessControl) || (item.flow && $store.getters.subAdminAccessControl.assignedFlows.includes(item.flow))">
              <v-card class="mx-auto" width="300" style="margin-bottom: 5%">
                <v-list>
                  <v-list-group no-action sub-group :value="(item.flow == defaultSelectedFlow) ? true : false">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title style="color: rgba(0, 0, 0, 0.6); font-size: 16px">
                          {{item.flow}}

                          <div class="text-center">
                            <v-dialog v-model="dialog_deleteFlow" width="40%" :retain-focus="false">
                              <template v-slot:activator="{ on, attrs }">

                                <v-icon v-if="item.flow != 'default'" small v-bind="attrs" v-on="on"
                                  style="position:absolute; right:20px; top:12px" color="red"
                                  :disabled="disableDeleteflow" @click="flowToDelete=item.flow">
                                  fas fa-trash
                                </v-icon>
                              </template>
                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Warning!
                                </v-card-title>
                                <br>
                                <v-card-text>
                                  <p style="font-weight: bold">Are you sure you want to delete the flow ?</p>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn depressed color="error" @click="dialog_deleteFlow = false; deleteFlow() ">
                                    Delete
                                  </v-btn>
                                  <v-btn color="primary" text @click="dialog_deleteFlow = false">
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </div>


                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <!--option to configure journey type-->
                    <v-divider></v-divider>
                    <v-list-item style="padding-left:10%; padding-right:10%">
                      <v-list-item-content>
                        <v-radio-group v-model="item.journeyType">
                          <v-radio class="journey-type-radio" :key="'Assisted VKYC'" :label="'Assisted VKYC'"
                            :value="'VKYC'" :id="'VKYC'" @click="onJourneyTypeSelection($event, item)" />
                          <v-radio class="journey-type-radio" :key="'Unassisted VKYC'"
                            :label="'Unassisted VKYC'" :value="'UVKYC'" :id="'UVKYC'"
                            @click="onJourneyTypeSelection($event, item)"
                          />
                          <v-radio class="journey-type-radio" :key="'Assisted + Unassisted'"
                            :label="'Assisted + Unassisted'" :value="'AUVKYC'" :id="'AUVKYC'"
                            @click="onJourneyTypeSelection($event, item)" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider style="margin-bottom:10px;"></v-divider>
                    <!--option to configure journey type end-->
                    <!-- <template v-if="item.journeyType == 'VKYC' || item.journeyType == 'AUVKYC'"> -->
                    <template v-if="item.journeyType != 'UVKYC'">
                      <!-- Iterate over each item in item.data -->
                      <div v-for="(data, index) in item.data" :key="index">

                        <!-- Render v-list-group if item has chilren -->
                        <v-list-item-group  
                          v-if="data.children && enablePool[item.flow]" 
                          append-icon=""
                          :value="true"
                        >
                          <v-list-item 
                            style="padding-left:20%; padding-right:15%"
                            @click="$router.push({ path: data.children[0].path })"
                          >
                            <v-list-item-action>
                              <v-icon>
                                {{ data.icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="text-left">
                              <v-list-item-title>{{ data.text }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <!-- Iterate over children to render them as v-list-item -->
                          <v-list-item v-for="(child, i) in data.children" :key="i"
                            :class="$route.path.includes(child.path) ? 'activeMenu' : ''"
                            style="padding-left: 30%"
                            @click="$router.push({ path: child.path })">
                            <v-list-item-action v-if="child.icon">
                              <v-icon :color="$route.path.includes(child.path) ? 'app_bar_icon' : ''">
                                {{ child.icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="text-left">
                              <v-list-item-title>{{ child.text }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>

                        <v-list-item v-else
                          :class="data.path === $route.path ? 'activeMenu' : ''"
                          @click="($route.path !== data.path) ? $router.push({ path: data.path }) : ''"
                          style="padding-left:20%; padding-right:15%">

                          <v-list-item-action>
                            <v-icon :color="data.path === $route.path ? 'app_bar_icon' : ''">
                              {{ data.icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content :class="'text-left'">
                            <v-list-item-title>
                              <template v-if="data.showTooltip">
                                <v-tooltip v-show="data.showTooltip" right>
                                  <template v-slot:activator="{ on }">
                                    <p class="tooltipText" v-on="on">{{ data.text }}</p>
                                  </template>
                                  <span>{{ data.text }}</span>
                                </v-tooltip>
                              </template>
                              <template v-else>
                                <span>{{ data.text }}</span>
                              </template>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <!-- Render v-list-group if item has children -->
                        
                      </div>
                    </template>

                    <!--config options for unassisted journey-->
                    <template v-if="item.journeyType == 'UVKYC' || item.journeyType == 'AUVKYC'">
                      <v-list-item v-for="(item, index) in item.ukycData" :key="index + 5"
                        :class="item.path === $route.path ? 'activeMenu' : ''"
                        @click="($route.path !== item.path)  ? $router.push({ path: item.path }) : ''"
                        style="padding-left:20%; padding-right:15%">
                        <v-list-item-action>
                          <v-icon :color="item.path === $route.path ? 'app_bar_icon' : ''">
                            {{ item.icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content :class="
                          $route.path.includes('/settings/') ? 'text-right' : 'text-left'
                        ">
                          <v-list-item-title>
                            <template v-if="item.showTooltip">
                              <v-tooltip v-show="item.showTooltip" right>
                                <template v-slot:activator="{ on }">
                                  <p class="tooltipText" v-on="on">{{ item.text }}</p>
                                </template>
                                <span>{{ item.text }}</span>
                              </v-tooltip>
                            </template>
                            <template v-else>
                              <span>{{ item.text }}</span>
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <!--config options for unassisted journey-->
                  </v-list-group>
                </v-list>
              </v-card>
            </template>
            <template v-else>
              <v-list-item-action>
                <v-icon :color="item.path === $route.path ? 'app_bar_icon' : ''">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content :class="
                $route.path.includes('/settings/') ? 'text-right' : 'text-left'
              ">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item v-for="(child, i) in item.children" :key="i" link active-class="activeMenu"
                :class="$route.path.includes(child.path) ? 'activeMenu' : ''"
                @click="$router.push({ path: child.path })">
                <v-list-item-action v-if="child.icon">
                  <v-icon :color="
                    $route.path.includes(child.path) ? 'app_bar_icon' : ''
                  ">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-right">
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <div class="text-center">
      <v-dialog v-model="logoutDialog" persistent width="35%">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure, you want to logout?
          </v-card-title>
          <br>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="logoutDialog = false">
              Close
            </v-btn>
            <v-btn depressed color="error" @click="logout(true)">
              Logout
              <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="app_bar_color" dark>
      <v-app-bar-nav-icon @click.stop="toogleSidebar()" />
      <v-toolbar-title style="width: 300px; display: flex" justify="center" class="ml-0 pl-4">
        <img v-if="showImageLogo" height="30px;" :src="logoLink" />
      </v-toolbar-title>
      <v-spacer />

      <!--logged in Admin name-->
      <div style="display:contents;">
        <div>
          <img src="@/assets/loginUser.svg" style="vertical-align:middle;" />
        </div>
        <div style="margin-left:5px;">
          <div style="line-height:15px;font-size:18px;font-weight:bold;" :title='this.adminData.name'>

            {{this.adminData && this.adminData.name && this.adminData.name.length >= 15 ?
            this.adminData.name.substring(0,15) + '...' : this.adminData.name }}
          </div>
          <div style="line-height:15px;" :title='this.adminData.username' v-if="!config.global.hideUsernameDashboard && !this.adminData.hideUsernameDashboard">
            {{this.adminData && this.adminData.username && this.adminData.username.length >= 20 ?
            this.adminData.username.substring(0,20) + '...' : this.adminData.username}}
          </div>
        </div>
      </div>

      <v-btn depressed v-if="!isSetting" color="app_bar_color" @click="switchView(true)">
        Settings
        <v-icon class="ml-2">fas fa-cog</v-icon>
      </v-btn>
      <v-btn depressed v-if="isSetting && !redirectSettings" color="app_bar_color" @click="switchView(false)">
        Dashboard
        <v-icon class="ml-2">fas fa-pager</v-icon>
      </v-btn>
      <v-btn depressed color="app_bar_color" @click="logout()">
        Logout
        <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import { sideBar, end_points } from "@/config";
import { rightClickBlock, disableInspect } from "@/Plugins/utils.js";
import { downloadFile} from "@/flux/persist.flux";
export default {
  props: {
    source: String,
  },
  data: () => ({
    adminData: {},
    flows: [],
    newFlow: "",
    toggleAddNewFlow: false,
    config: {},
    disableAddflow: false,
    disableDeleteflow: false,
    defaultSelectedFlow: "default",
    dummy: [],
    dummyUVKYC: [],
    dialog: false,
    dialog_deleteFlow: false,
    drawer: null,
    mini: false,
    isSetting: false,
    items: sideBar.controlPanel,
    isVideoKYC: false,
    isVideoConference: false,
    isActive: false,
    redirectSettings: false,
    idleTime: 0,
    idleTimeout: 10, //in mins
    idleInterval: {},
    showImageLogo: false,
    logoLink: "",
    flowToDelete: null,
    flowsToAdd: [],
    allFlowsOfCustomer: [],
    logoutDialog: false,
    enablePool: {}
  }),
  methods: {
    keepExpanded(active) {
      if (!active) {
        this.$emit('update:active', true);
      }
    },
    timerIncrement() {
      this.idleTime = this.idleTime + 1;
      if (this.idleTime > this.idleTimeout) {
        // 20 minutes
        window.clearInterval(this.idleInterval);
        axiosInstance.get(end_points.logout(sessionStorage.userId));
        eventBus.$emit("vueLoader", false);
        if (this.$store.getters.userData.config?.global?.redirectOnLogout) {
          let urlToSend = this.$store.getters.userData.config.global.redirectOnLogoutURL;
          store.flush();
          window.open(urlToSend, "_self");
        } else {
          store.flush();
          this.$router.push({
            path: "/admin",
          });
        }
      }
    },
    toogleSidebar() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.$route.path.includes('/settings/') 
        ? this.drawer = !this.drawer 
        : this.drawer = true;      
        this.mini = !this.mini;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },
    manageFlow(flow) {
      let tempObj = {};
      let UtempObj = {};
      this.dummy = [];
      this.Udummy = [];

      sideBar.settings.forEach(element => {
        tempObj = { ...element };
        if (flow != "default" || (flow == "default" && element.text !== "Call Configurations")) {
          if (element.text == 'Questionnaires' && tempObj.children?.length && this.enablePool[flow]) {
            tempObj.path = `${element.path}/mandatory/${flow}`;
          } else {
            tempObj.path = `${element.path}/${flow}`;
          }
          if (tempObj.children?.length) {
            let tempChildren = [];
            for (const child of tempObj.children) {
              let tempChild = { ...child };
              tempChild.path += `/${flow}`;
              tempChildren.push(tempChild);
            }
            tempObj.children = tempChildren;
          }
        }
        this.dummy.push({ ...tempObj });
      });

      sideBar.unassistedSettings.forEach(element => {
        UtempObj = { ...element };
        UtempObj.path = `${UtempObj.path}/${flow}`;
        this.Udummy.push({ ...UtempObj });
      });

      let flowJourneyType = 'VKYC';
      if (flow == 'default') {
        flowJourneyType = this.config && this.config.journeyType ? this.config.journeyType : 'VKYC';
      } else {
        flowJourneyType = this.config.multiflow[flow] && this.config.multiflow[flow].journeyType ? this.config.multiflow[flow].journeyType : 'VKYC';
      }

      if (this.$store.getters.subAdminAccessControl) {
        this.allFlowsOfCustomer.push({ flow: flow, data: [...this.dummy], ukycData: [...this.Udummy], journeyType: flowJourneyType });
        if (this.$store.getters.subAdminAccessControl.assignedFlows.includes(flow))
          this.items.push({ flow: flow, data: [...this.dummy], ukycData: [...this.Udummy], journeyType: flowJourneyType });
      } else {
        this.items.push({ flow: flow, data: [...this.dummy], ukycData: [...this.Udummy], journeyType: flowJourneyType });
      }
    },
    createNewFlow(flow){
      if(flow.length < 2 || flow.length > 15){
        window.snackBar("Flow Name must be between 2 to 15 characters");
        return;
      } 
      let allFlows = this.items.map(item => item.flow.toLowerCase())

      if(flow.toLowerCase() == "global" || flow.toLowerCase() == "default"){
        window.snackBar(`Cannot create a flow named "${flow}".`);
        return;
      }

      if(this.$store.getters.subAdminAccessControl){
        let customerFlows = this.allFlowsOfCustomer.map(item => item.flow.toLowerCase());
        if(customerFlows.includes(flow.toLowerCase())){
          window.snackBar(`${flow} flow already exists.`);
          return;
        }
      }else{
        if(allFlows.includes(flow.toLowerCase())){
          window.snackBar(`${flow} flow already exists.`);
          return;
        }
      }

      if(this.$store.getters.subAdminAccessControl){
        let existingAccess = this.$store.getters.subAdminAccessControl;
        this.$store.commit("setSubAdminAccessControl", {
          assignedFlows: [...existingAccess.assignedFlows, flow],
          allowFlowCreation: existingAccess.allowFlowCreation,
          allowFlowDeletion: existingAccess.allowFlowDeletion,
          allowEditingSubAdminAccess: existingAccess.allowEditingSubAdminAccess
        });
      }
      this.manageFlow(flow)
      this.config.multiflow[flow] = {};
      this.toggleAddNewFlow = !this.toggleAddNewFlow
      if(this.newFlow){
        this.flowsToAdd.push(this.newFlow)
      }
      this.newFlow = "";
      this.$router.push({
        path: this.dummy[0].path,
      });
    },
    loadFlowData(){
      this.items = [];
      if(this.$store.getters.subAdminAccessControl){
        this.$store.getters.subAdminAccessControl.assignedFlows.includes("default") && this.manageFlow("default");
      } else {
        this.manageFlow("default");
      }
      let tempConfig = {...this.config};
      
      if(tempConfig.multiflow && Object.keys(tempConfig.multiflow).length > 0){
        this.flows = Object.keys(tempConfig.multiflow)
        this.flows.forEach(flow => { 
          this.manageFlow(flow)
        });
      }

      //updating journey type in flow configs
      if(tempConfig) {
        tempConfig.journeyType = tempConfig.journeyType ? tempConfig.journeyType : 'VKYC';
        if(tempConfig.multiflow && Object.keys(tempConfig.multiflow).length > 0) {
          for(let key in tempConfig.multiflow) {
            let flowObj = tempConfig.multiflow[key];
            flowObj.journeyType = flowObj.journeyType ? flowObj.journeyType : 'VKYC';
          }
        }
      }
    },
    onJourneyTypeSelection(event, item){
      if(item.flow == 'default') {
        this.config['journeyType'] = item.journeyType;
        sessionStorage.setItem('isMultiflowControlPanel', false);
      } else {
        this.config.multiflow[item.flow].journeyType = item.journeyType;
        sessionStorage.setItem('isMultiflowControlPanel', true);
        sessionStorage.setItem('multiflowIdControlPanel', JSON.stringify(item.flow));
      }
      let configData = JSON.stringify(this.config);
      sessionStorage.setItem('configUpdateFromControlPanel', configData);
      eventBus.$emit("updateConfig", ["journeyType"]);
      eventBus.$once("updatedSuccessfully", (flag)=>{
        this.$nextTick(()=> {
          this.loadFlowData();
          if(item.journeyType === "UVKYC") {
            sessionStorage.setItem("journeyType", "UVKYC");
            this.$router.push({ path: `/admin/control/settings/uvkyc-basic${item.flow != "default" ? "/" + item.flow : "/default"}` });
          } else {
            this.$router.push({ path: `/admin/control/settings/basic${item.flow != "default" ? "/" + item.flow : ""}` });
          }
        })
      });
    },
    deleteFlow(){
      delete this.config.multiflow[this.flowToDelete];
      this.switchView(true);
      eventBus.$emit("updateConfig", {toDelete: this.flowToDelete});
      eventBus.$emit("deleteSubAdminFlow", this.flowToDelete);
    },
    switchView(isSetting) {
      if (isSetting) {
        if (this.isVideoKYC) {
          if(this.$store.getters.subAdminAccessControl){
            if(this.$store.getters.subAdminAccessControl.assignedFlows.length > 0){
              this.isSetting = isSetting;
              this.loadFlowData();
              const isOnlyUnassisted = this.$store.getters.subAdminAccessControl?.defaultJourneyType == "UVKYC";
              let customPath = `/admin/control/settings/${
                this.$store.getters.subAdminAccessControl.assignedFlows[0] == "global"
                  ? "global-settings"
                  : this.$store.getters.subAdminAccessControl.assignedFlows[0] == "default"
                  ? isOnlyUnassisted
                    ? "uvkyc-basic/default"
                    : "basic"
                  : (isOnlyUnassisted ? "uvkyc-basic/" : "basic/") + this.$store.getters.subAdminAccessControl.assignedFlows[0]
              }`;
              this.defaultSelectedFlow = this.$store.getters.subAdminAccessControl.assignedFlows[0];
              this.disableAddflow = !this.$store.getters.subAdminAccessControl.allowFlowCreation;
              this.disableDeleteflow = !this.$store.getters.subAdminAccessControl.allowFlowDeletion;
              this.$router.push({ path: customPath });
            } else{
              this.isSetting = false;
              eventBus.$emit("vueSnack", "No flow has been assigned, cannot view settings page.");
            }
          } else {
            this.isSetting = isSetting;
            this.loadFlowData();
            if(sessionStorage.getItem("journeyType") == "UVKYC") {
              this.$router.push({ path: "/admin/control/settings/uvkyc-basic/default" });
            } else {
              this.$router.push({ name: "settingsLanding" });
            }
          }
        }
      } else {
        this.isSetting = isSetting;  
        this.loadFlowData();
        this.items = sideBar.controlPanel;
        this.isSetting = isSetting;
        this.$router.push({
          name: "controlPanelLanding",
        });
      }
    },
    async showAdminData(admin) {
      if(!admin.data){
        let adminInfo = (await axiosInstance.get(end_points.get_agent_data(admin.userId))).data;
        this.adminData = {
          name: adminInfo.name, username: adminInfo.username
        }
      }
      else{
        this.adminData = {
          name: admin.data.name, username: admin.data.username
        }
      } 
    },
    logout(isBypass) {
      if (isBypass) {
        axiosInstance.get(end_points.logout(sessionStorage.userId));
        eventBus.$emit("vueLoader", false);
        if (this.$store.getters.userData.config?.global?.redirectOnLogout) {
          let urlToSend = this.$store.getters.userData.config.global.redirectOnLogoutURL;
          store.flush();
          window.open(urlToSend, "_self");
        } else {
          store.flush();
          this.$router.push({
            path: "/admin",
          });
        }
      } else {
        this.logoutDialog = true;
      }
    },
  },
  created() {
    if(!this.$store.getters.userData || !sessionStorage.getItem('userId')){
      this.$router.push(process.env.VUE_APP_ADMIN_LOGIN_PATH || '/admin/login');
    }else{
      let isIdleTimeNeeded;
    this.redirectSettings = this.$store.getters.envValues?.redirectCustomerSettings == "true" ? true : false;

    eventBus.$on("logout", (data) => {
      this.logout(data);
    });

    eventBus.$on("enablePool", (props)=>{
      this.enablePool = {
        ...this.enablePool,
        [props.flow]: props.flag
      }
    });

    eventBus.$on("updatedSuccessfully", async ()=>{
      if(this.$store.getters.subAdminAccessControl){
        if(this.flowsToAdd.length > 0){
          let index = this.flowsToAdd.indexOf(this.$route.params.id);
          if(index > -1){
            try {
              let response = await axiosInstance.post(end_points.self_update_flow_list, {
                flowToAdd: this.$route.params.id
              });
              if(response?.data?.status === "Success"){
                this.flowsToAdd.splice(index, 1);
              } else {
                eventBus.$emit("vueSnack","Something went wrong while adding new flow by subadmin!");
              }
            } catch(error){
              console.log("Error while trying to self update subAdmin assigned flows", error)
            }
          }
        }
      }
    });

    eventBus.$on("deleteSubAdminFlow", async (flowName)=>{
      if(this.$store.getters.subAdminAccessControl){
        if(flowName){
          try {
            let response = await axiosInstance.post(end_points.self_update_flow_list, {
              flowToDelete: flowName
            });
            if(response?.data?.status === "Success"){
              this.flowToDelete = null;
            } else {
              eventBus.$emit("vueSnack","Something went wrong while deleting flow by subadmin!");
            }
          } catch(error){
            console.log("Error while trying to self update subAdmin assigned flows", error)
          }
        }
      } else {
        if(flowName){
          try {
            let allUsers = (await axiosInstance.get(end_points.get_all_admins(this.$store.getters.userData.id))).data;
            let adminIdsToUpdate = allUsers.filter(item => item.belongsTo == 0 && item.assignedFlows?.includes(flowName)).map(item => item.id);

            let response = await axiosInstance.post(end_points.bulk_update_sub_admin_flow_list, {
              adminIdsToUpdate: adminIdsToUpdate,
              flowToDelete: flowName
            });
            if(response?.data?.status === "Success"){
              this.flowToDelete = null;
            } else {
              eventBus.$emit("vueSnack","Something went wrong while removing flows for sub admins!");
            }
          } catch(error){
            console.log("Error while trying to bulk update subAdmin assigned flows", error)
          }
        }
      }
    });

    if (this.$store.getters.userData.customerData) {
      this.isVideoConference = this.$store.getters.userData.customerData
        .isVideoConference
        ? this.$store.getters.userData.customerData.isVideoConference
        : false;

      this.isVideoKYC = this.$store.getters.userData.customerData.isVideoKYC
        ? this.$store.getters.userData.customerData.isVideoKYC
        : false;
    } else {
      this.isVideoConference = this.$store.getters.userData.isVideoConference
        ? this.$store.getters.userData.isVideoConference
        : false;

      this.isVideoKYC = this.$store.getters.userData.isVideoKYC
        ? this.$store.getters.userData.isVideoKYC
        : false;
    }

    if (window.location.href.toLowerCase().includes("audit")) {
      this.isActive = true;
    }
    if (window.location.href.includes("settings")) {
      this.switchView(true);
    } else {
      this.isSetting = false;
      this.items = sideBar.controlPanel;
    }

    if (this.redirectSettings) {
      this.switchView(true);
    }

    //Pulling customer config
    let userData = this.$store.getters.userData;
    axiosInstance.defaults.headers.common["Authorization"] = userData.token;
    this.showAdminData(this.$store.getters.userData);
    let userId;
    if (userData.customerData) {
      userId = userData.customerData.id;
    } else if (userData.userId) {
      userId = userData.userId;
    }

    if (userId) {
      axiosInstance
        .get(end_points.get_customer_data(userId))
        .then( async(resp) => {
          this.config = {...resp.data.config}
         
         //if there is no default flow like for new customer
          // if(!this.config.instructions){
          //   //do not allow to add new flow
          //   this.disableAddflow = true;
          // }

          sessionStorage.setItem("journeyType", this.config?.journeyType ?? "VKYC");

          if((this.config && this.config.fields && this.config.fields.length) && (this.config.instructions && this.config.instructions.length) 
                && (this.config.introConf && Object.keys(this.config.introConf).length != 0)){
                  this.disableAddflow = false;
          }else{
            this.disableAddflow = true;
          }
          if(this.$route.path.includes('/settings/')){
            this.loadFlowData();
          }
          this.idleTimeout = resp.data.config?.global?.idleTime || 10;
          if (resp.data.config.introConf.imageLogo) {
            this.showImageLogo = true;
            if (resp.data.config.introConf.imageLogo.includes("persist")) {
              let respImg = await downloadFile(resp.data.config.introConf.imageLogo);
              if (respImg.status === 200) {
                this.logoLink = `data:${respImg.headers["content-type"].split(";")[0]};base64, ${respImg.data.file}`;
              } 
            } else {
              this.logoLink = resp.data.config.introConf.imageLogo;
            }
          }

          try {
            isIdleTimeNeeded =
              resp.data.config?.global?.idleTimeRequired || false;
          } catch (error) {
            console.log(error);
            isIdleTimeNeeded = false;
          }

          if (resp.data.config.introConf.disableRightClick) {
            document.addEventListener("contextmenu", rightClickBlock);
            document.onkeydown = disableInspect;
          } else {
            document.removeEventListener("contextmenu", rightClickBlock);
            document.onkeydown = {};
          }

          if (isIdleTimeNeeded) {
            window.clearInterval(this.idleInterval);
            this.idleInterval = setInterval(this.timerIncrement, 1000 * 60); // 1 minute
            let appHtml = document.getElementById("app");
            appHtml.addEventListener("mousemove", (e) => {
              this.idleTime = 0;
            });
            appHtml.addEventListener("keypress", (e) => {
              this.idleTime = 0;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }


    }
    eventBus.$on("configUpdateStatus", (data) => {
      if(data){
        let userData = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = userData.token;
        let userId;
        if (userData.customerData) {
          userId = userData.customerData.id;
        } else if (userData.userId) {
          userId = userData.userId;
        }
      
        if(userId){
          axiosInstance.get(end_points.get_customer_data(userId))
          .then((resp)=>{
            if(resp && resp.status == 200){
              if(resp.data && resp.data.config){
                let configObj = resp.data.config;
                if((configObj && configObj.fields && configObj.fields.length) && (configObj.instructions && configObj.instructions.length) 
                  && (configObj.introConf && Object.keys(configObj.introConf).length != 0)){
                    this.disableAddflow = false;
                }
              }
            }else{
                this.disableAddflow = true;
            }
          }).catch((error) => {
            console.log(error);
          });
        }else{
          this.disableAddflow = true;
        }
      
      }
    });
  },
};
</script>
<style scoped>
.activeMenu {
  background: #d4d2d2;
  border-right: 4px solid #0184c4;
}

.tooltipText {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.journey-type-radio >>> i{
    font-size: 20px;
}

.journey-type-radio >>> label{
    font-size: 16px;
}
</style>

<template>
  <v-container>
    <router-view :config="config" :currentJourneyType="currentJourneyType" />
  </v-container>
</template>

<script>
import { end_points, constants } from "@/config";
import enLib from "crypto-js/aes";
let axiosInstance = window.axiosInstance;
let store = window.store;
let snackBar = window.snackBar;
let eventBus = window.eventBus;
export default {
  data: () => ({
    config: {
      languagesOptions: null,
      global: {},
      introConf: {},
      fields: [],
      instructions: [],
      callPriority: {},
      conference: {},
      journeyType: '',
      multiflow:{}
    },
    user: null,
    customerId: "",
    localData: {},
    multiFlow: false,
    multiFlowId: null,
    currentJourneyType: "VKYC"
  }),
  methods: {
    updateConfig(configKey) {

      //keeping it for future ref
      // let config = { ...this.user.config };
      // // configKey.forEach((configEntry) => {
      // //   config = { ...config, [configEntry]: this.config[configEntry] };
      // // });
      let dataFromSessionForConfig = JSON.parse(sessionStorage.getItem("configUpdateFromControlPanel"));
      if(dataFromSessionForConfig) {
        this.config = dataFromSessionForConfig;
      }
    
      if(sessionStorage.getItem('isMultiflowControlPanel') && sessionStorage.getItem('isMultiflowControlPanel') == 'false'){
        this.multiFlow = false;
      }

      if(sessionStorage.getItem('isMultiflowControlPanel') && sessionStorage.getItem('isMultiflowControlPanel') == 'true'){
        this.multiFlow = true;
        this.multiFlowId = JSON.parse(sessionStorage.getItem('multiflowIdControlPanel'))
      }

      

      eventBus.$emit('vueLoader', true);
      if(configKey.toDelete){ //Used to delete a flow
        if(this.config.multiflow[configKey.toDelete]){
          delete this.config.multiflow[configKey.toDelete]
        }else{
          eventBus.$emit('vueLoader', false);
          return;
        }
      } else {
        //Adding logic in else block since during a flow deletion "config" props is not required to be updated
        configKey.forEach((conf)=>{
          //This is for updating multiflow settings
          if(this.multiFlow)  {
            //updating config object for unassisted journey fields
            if(conf == 'journeyType' && this.isRelatedToUnassisted(this.config.multiflow[this.multiFlowId][conf]) && this.localData.multiflow[this.multiFlowId]
              && this.config.multiflow[this.multiFlowId]['uvkycInstructionsSet'] == undefined) {
              this.config.multiflow[this.multiFlowId]['uvkycInstructionsSet'] = [{questionType: 'OTP',text: '',enableScreenshot: false}];
              
              if(this.config.multiflow[this.multiFlowId]['uvkycIntroConf'] == undefined) {
                this.config.multiflow[this.multiFlowId]['uvkycIntroConf'] = this.isRelatedToUnassisted(this.config.journeyType) && this.config.uvkycIntroConf ? this.config.uvkycIntroConf : {};
                this.localData.multiflow[this.multiFlowId]['uvkycIntroConf'] = this.config.multiflow[this.multiFlowId]['uvkycIntroConf'];
              }
              this.localData.multiflow[this.multiFlowId]['uvkycInstructionsSet'] = this.config.multiflow[this.multiFlowId]['uvkycInstructionsSet'];
            }
            //Check if a new flow has been created for which localData is not present
            if(!this.localData.multiflow[this.multiFlowId] && this.config.multiflow[this.multiFlowId]){
              if(conf == 'journeyType' && this.isRelatedToUnassisted(this.config.multiflow[this.multiFlowId][conf]) 
                && this.config.multiflow[this.multiFlowId]['uvkycInstructionsSet'] == undefined){
                   this.config.multiflow[this.multiFlowId]['uvkycInstructionsSet'] = [{questionType: 'OTP',text: '',enableScreenshot: false}];
                  if(this.config.multiflow[this.multiFlowId]['uvkycIntroConf'] == undefined) {
                      this.config.multiflow[this.multiFlowId]['uvkycIntroConf'] = this.isRelatedToUnassisted(this.config.journeyType) && this.config.uvkycIntroConf ? this.config.uvkycIntroConf : {};
                  }
              }

              if(this.config.multiflow[this.multiFlowId]['introConf'] == undefined) {
                this.config.multiflow[this.multiFlowId]['introConf'] = this.config.introConf;
                this.config.multiflow[this.multiFlowId]['instructions'] = this.config.instructions;
                this.config.multiflow[this.multiFlowId]['instructionPage'] = this.config.instructionPage;
                this.config.multiflow[this.multiFlowId]['fields'] = this.config.fields;
                this.config.multiflow[this.multiFlowId]['languagesOptions'] = this.config.languagesOptions
              }
              
              //Removing nested global config from newly created flow
              this.$delete(this.config.multiflow[this.multiFlowId], "global");

              //Updating the localData with the newly created flow
              this.localData = JSON.parse(JSON.stringify(this.config));

            }

            //Assigning the updated value to local instance for multiflow
              this.localData.multiflow[this.multiFlowId][conf] = this.config.multiflow[this.multiFlowId][conf];


          }else{
            //updating config object for unassisted journey fields
            if(conf == 'journeyType' && this.isRelatedToUnassisted(this.config[conf]) && this.config['uvkycInstructionsSet'] == undefined) {
              this.config['uvkycInstructionsSet'] = [{questionType: 'OTP',text: '',enableScreenshot: false}];
              this.localData['uvkycInstructionsSet'] = this.config['uvkycInstructionsSet'];
            }
            
            //Assigning the updated value to local instance for default settings
            this.localData[conf] = this.config[conf];
          }
        });
        //Altering the orignal copy of config using deep copy with the UPDATED local copy
        this.config = JSON.parse(JSON.stringify(this.localData));
      }

      // encryption on post data
      let dataToPost = null;
      dataToPost = enLib.encrypt(JSON.stringify(this.config), this.$store.getters.envValues.otpEncKey).toString();

      axiosInstance
        .patch(
          end_points.get_customer_data(this.user.id),
          { dataToPost }
        )
        .then((resp) => {
          this.user = resp.data;
          this.config = {
            ...this.config,
            ...JSON.parse(JSON.stringify(this.user.config || {})),
          };

          snackBar("Update Successful");
          //removing control panel config object from session
          if(sessionStorage.getItem("configUpdateFromControlPanel")){
            sessionStorage.removeItem("configUpdateFromControlPanel")
          }

          if(sessionStorage.getItem('isMultiflowControlPanel')){
            sessionStorage.removeItem('isMultiflowControlPanel')
            sessionStorage.removeItem('multiflowIdControlPanel');
          }
          this.multiFlow = false;
          eventBus.$emit('configUpdateStatus', true);
          //Sending an event to confirm a successful update along with a flag
          eventBus.$emit('updatedSuccessfully', true);
          eventBus.$emit('vueLoader', false);
        })
        .catch(() => {
          snackBar("Update Failed!");
          if(sessionStorage.getItem("configUpdateFromControlPanel")){
            sessionStorage.removeItem("configUpdateFromControlPanel")
          }
          if(sessionStorage.getItem('isMultiflowControlPanel')){
            sessionStorage.removeItem('isMultiflowControlPanel')
            sessionStorage.removeItem('multiflowIdControlPanel');
          }
          eventBus.$emit('vueLoader', false);
        });
    },
    isRelatedToUnassisted(journeyType){
      return journeyType == "AUVKYC" || journeyType == "UVKYC";
    }
  },
  created() {
    let selfIns = this;
    let userData = this.$store.getters.userData;
    if (userData.customerData) {
      this.customerId = userData.customerData.id;
    } else {
      this.customerId = userData.userId
    }
    axiosInstance.defaults.headers.common["Authorization"] = userData.token;
    if (userData.userId) {
      axiosInstance
        .get(end_points.get_customer_data(this.customerId))
        .then((resp) => {
          this.user = resp.data;
          this.config = {
            ...this.config,
            ...JSON.parse(JSON.stringify(this.user.config || {})),
          };

          if (this.config.introConf.isThankYouMailRequired == undefined) {
            this.config.introConf.isThankYouMailRequired = true;
          }

          if (this.config.instructionPage == undefined) {
            this.$set(this.config, "instructionPage", {});
            this.$set(
              this.config.instructionPage,
              "defaultInstructionScheme",
              false
            );
            this.$set(this.config.instructionPage, "instructions", [
              {
                title: "",
                body: "",
              },
            ]);
          }
          // creating a deep copy of config for local referencing
          this.localData = JSON.parse(JSON.stringify(this.config));

        })
        .catch((error) => {
          console.log(error);
        });
    }

    //updaing the settings
    eventBus.$on("updateConfig", (data) => {
      selfIns.updateConfig(data);
    });
  },
  updated(){
    //Selecting the route from params.id
    const routeSelection = this.$route.params.id ? this.$route.params.id.toString() : "default";
    //This is for multiflow settings update, anything that's not default is multiflow
    if(routeSelection !== "default") {
      this.multiFlow = true;
      this.multiFlowId = this.$route.params.id;
      this.currentJourneyType = this.config?.multiflow?.[routeSelection]?.journeyType ?? "VKYC";
    } else  {
      //This is for default settings update
      this.multiFlow = false;
      this.multiFlowId = null;
      this.currentJourneyType = this.config?.journeyType ?? "VKYC";
    }
  }
};
</script>

<style>
.primary-text {
  color: #07a5cb;
}
</style>

<template>
	<v-row justify="center" class="primary-text">
		<v-col cols="12" sm="12" md="10" lg="10" xl="10">
			<v-card class="mb-5">
				<v-container>
					<v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
						<div class="headline pb-1">Instruction Page Configurations</div>
						<v-divider class="ml-5 mb-3"></v-divider>
						<v-checkbox class="mb-n5" v-model="localConfig.instructionPage.disableInstructionsPage" label="Do you want to disable instruction page for this flow?"></v-checkbox>
						<div v-if="!localConfig.instructionPage.disableInstructionsPage">
							<v-checkbox v-model="localConfig.instructionPage.defaultInstructionScheme" label="Do you want to have default instruction scheme?"></v-checkbox>
							<div v-if="localConfig.instructionPage.defaultInstructionScheme">
								<v-row justify="center">
									<v-col sm="10" cols="10" md="8" xl="6" lg="6">
										<v-row justify="center" v-if="!isMobile">
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="right">
												<img class="instructionImg" src="@/assets/pancard.svg" />
											</v-col>
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="left" class="align-self-center instructionText">Join the call and show your PAN as directed</v-col>
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="right" class="align-self-center instructionText"
												>Follow the instructions till {{ displayName }} official captures the required details</v-col
											>
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="left">
												<img class="instructionImg" src="@/assets/instructions.svg" />
											</v-col>
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="right">
												<img class="instructionImg" src="@/assets/interogation.svg" />
											</v-col>
											<v-col sm="10" cols="10" md="6" xl="6" lg="6" align="left" class="align-self-center instructionText"
												>Answer the questions asked by {{ displayName }} official for verification purposes</v-col
											>
										</v-row>
										<v-row justify="center" align="center" v-else>
											<v-col cols="10">
												<img class="instructionImg" src="@/assets/pancard.svg" />
											</v-col>
											<v-col cols="10">Join the call and show your PAN as directed</v-col>
											<v-col cols="10">
												<img class="instructionImg" src="@/assets/instructions.svg" />
											</v-col>
											<v-col cols="10">Follow the instructions till {{ displayName }} official captures the required details</v-col>
											<v-col cols="10">
												<img class="instructionImg" src="@/assets/interogation.svg" />
											</v-col>
											<v-col cols="10">Answer the questions asked by {{ displayName }} official for verification purposes</v-col>
										</v-row>
									</v-col>
								</v-row>
							</div>
							<div v-else>
								<div class="title pb-1">Please add your custom instructions</div>
								<v-row v-for="(field, index) in localConfig.instructionPage.instructions" :key="index">
									<v-col cols="10">
										<v-text-field
											outlined
											label="Instruction Title"
											v-model="field.title"
											:ref="'variable_instruction_title_' + index"
											:rules="[...rule.emptyTitle, ...rule.spaceInputCheck, ...rule.specialCharsCheck]"
										></v-text-field>
										<v-textarea
											rows="3"
											outlined
											v-model="field.body"
											label="Instruction Body"
											:rules="[...rule.allowEmptyValueCheck, ...rule.specialCharsCheck]"
											:ref="'variable_instruction_body_' + index"
										></v-textarea>
									</v-col>
									<v-col cols="2">
										<v-btn @click="localConfig.instructionPage.instructions.splice(index, 1)" outlined color="red" block small>Delete</v-btn>
									</v-col>
								</v-row>
								<v-btn @click="addInstruction()" color="primary" outlined> <v-icon left dark>fas fa-plus</v-icon>Add Instruction </v-btn>
							</div>
						</div>
						<div class="text-right">
							<v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
						</div>
					</v-form>
				</v-container>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data: () => ({
		displayName: "",
		currentFlow: "",
		localConfig: {},
		isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
		languages: ["HINDI", "KANNADA", "BENGALI", "GUJARATI"],
		rule: {
			emptyTitle: [(value) => !!value || value.length || "Cannot leave instruction title empty!"],
			spaceInputCheck: [
				(value) => {
					if (value && !value.replace(/\s/g, "").length) {
						return "Please enter valid value, cannot take only spaces";
					}
					return true;
				}
			],
			allowEmptyValueCheck: [
				(value) => {
					if (value && /^\s+$/.test(value)) {
						return "Please enter valid value, cannot take only spaces";
					}

					return true;
				}
			],
			specialCharsCheck: [
				(value) => {
					if (value && value != "" && value.match(/[()~`<>*]/)) {
						return "Cannot take  ( ) ~ ` < > * in input";
					}

					return true;
				}
			]
		}
	}),
	methods: {
		checkEmptyness() {
			for (let i = 0; i < this.localConfig.instructionPage.instructions.length; i++) {
				if (this.localConfig.instructionPage.instructions[i].title.trim().length <= 0) {
					let x = "variable_instruction_title_" + i;
					this.highlighter(this.$refs[x][0]);
					// this.$refs[x] returns an array of length 1 with the desired element on index 0,
					// hence using this.$refs[x][0] to access that element
					return true;
				} else if (this.localConfig.instructionPage.instructions[i].title) {
					let x = "variable_instruction_title_" + i;
					if (this.$refs[x][0].value && !this.$refs[x][0].value.replace(/\s/g, "").length) {
						this.highlighter(this.$refs[x][0]);
						return true;
					}
					if (this.$refs[x][0].value && this.$refs[x][0].value.match(/[()~`<>*]/)) {
						this.highlighter(this.$refs[x][0]);
						return true;
					}
				}

				if (this.localConfig.instructionPage.instructions[i].body) {
					let x = "variable_instruction_body_" + i;
					if (this.$refs[x][0].value && !this.$refs[x][0].value.replace(/\s/g, "").length) {
						this.highlighter(this.$refs[x][0]);
						return true;
					}
					if (this.$refs[x][0].value && this.$refs[x][0].value.match(/[()~`<>*]/)) {
						this.highlighter(this.$refs[x][0]);
						return true;
					}
				}
			}
			return false;
		},
		highlighter(element) {
			// element passed is a ref
			element.focus();
			element.blur();
		},
		submitForm() {
			if (this.localConfig.instructionPage.disableInstructionsPage) {
				eventBus.$emit("updateConfig", ["instructionPage"]);
			} else if (this.localConfig.instructionPage.defaultInstructionScheme) {
				eventBus.$emit("updateConfig", ["instructionPage"]);
			} else if (this.localConfig.instructionPage.instructions.length > 10) {
				eventBus.$emit("vueSnack", "Number of instructions per page cannot be more than 10!");
			} else if (this.checkEmptyness()) {
				console.log("Instruction title is missing!!!");
			} else {
				if(this.currentFlow != "default"){
          			this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(this.localConfig));
        		} else{
          			this.config = JSON.parse(JSON.stringify(this.localConfig))
        		}
				eventBus.$emit("updateConfig", ["instructionPage"]);
				eventBus.$on("updatedSuccessfully", (flag)=>{
          			this.$nextTick(()=> {
            			this.getFlowData();
          			})
        		})
			}
		},
		getFlowData() {
			this.displayName = this.config.introConf.displayName;

			//DELETE: Commenting this code out for now, this part can be taken out later down the line during clean up
			// if (!this.config.instructionPage) {
			//   this.$set(this.config, "instructionPage", {});
			//   this.$set(this.config.instructionPage, "defaultInstructionScheme", false);
			//   this.$set(this.config.instructionPage, "instructions", [
			//     {
			//       title: "",
			//       body: "",
			//     },
			//   ]);
			// }

			if (this.currentFlow != "default") {
				this.localConfig = this.config.multiflow[this.currentFlow];
			} else {
				this.localConfig = this.config;
			}

			if (!this.localConfig.instructionPage) {
				this.$set(this.localConfig, "instructionPage", {});
				this.$set(this.config.instructionPage, "defaultInstructionScheme", false);
				this.$set(this.config.instructionPage, "instructions", [
					{
						title: "",
						body: ""
					}
				]);
			} else if (!this.localConfig.instructionPage.hasOwnProperty("disableInstructionsPage")) {
				this.$set(this.localConfig.instructionPage, "disableInstructionsPage", false);
			}
		},
		addInstruction() {
			this.localConfig.instructionPage.instructions.push({
				title: "",
				body: ""
			});
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.currentFlow = to.params.id;
		this.getFlowData();
		next();
	},
	created() {
		this.currentFlow = this.$route.params.id;
		this.getFlowData();
	},
	updated() {
      setTimeout(() => {
        this.getFlowData()
      },0);
  	},

	props: ["config"]
};
</script>

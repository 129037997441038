<template>
    <v-row justify="center" class="primary-text">
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <v-card class="mb-5">
          <v-container>
            <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
              <div class="headline pb-1">Mandatory Questionnaries / Instruction Sets</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-card
                class="elevation-5 mt-2 mb-5"
                hover
              >
                <v-card-text>
                    <v-row row wrap class="mb-5">
                        <v-col
                            cols="11"
                            sm="11"
                            lg="11"
                            xl="11"
                        >
                            <v-select
                                v-model="localConfig.introConf.mandatoryPoolSequence"
                                :ref="'pool_sequence'"
                                :items="poolSequenceItems"
                                label="Select order of questions"
                                :rules="[validateVariableSelector]"
                                v-on:focus="condVarTouched = true"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row row wrap>
                        <v-col
                            cols="11"
                            sm="11"
                            lg="11"
                            xl="11"
                        >
                            <v-text-field
                                style="margin-top:-2.188rem "
                                label="Total questions to display"
                                v-model="localConfig.introConf.displayMandatoryQuestions"
                                :ref="'pool_total'"
                                :rules="[displayQuestionsCheck]"
                                :error-messages="uniqueTextField(localConfig.introConf.displayMandatoryQuestions)"
                                type="number"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-card
                class="elevation-5 mt-2 mb-5"
                hover
                v-for="(field, index) in localConfig.instructions"
                :key="index"
              >
                <v-card-text v-if="field.mandatoryPool || field.mandatoryPool == undefined">
                  <v-row row wrap>
                    <v-col
                      cols="11"
                      sm="11"
                      lg="11"
                      xl="11"
                    >
                      <v-checkbox
                        style="white-space: pre"
                        v-model="field.isOTPInstuction"
                        class="mt-0 mb-0"
                        :label="otpLabel"
                        @change="checkForOtp(field)"
                      ></v-checkbox>

                      <v-checkbox
                        style="white-space: pre"
                        v-model="field.isPanInstruction"
                        class="mt-0 mb-0"
                        :label="panLabel"
                        @change="checkForPan(field)"
                      ></v-checkbox>
                    </v-col>
  
                    <v-col cols="1" justify="right">
                      <v-btn
                        @click="deleteInstruction(index)"
                        color="red"
                        fab
                        small
                      >
                        <v-icon small color="white">fas fa-trash</v-icon>
                      </v-btn>
                    </v-col>
  
                    <v-col cols="11" v-show="!field.isOTPInstuction">
                      <v-text-field
                        style="margin-top:-2.188rem "
                        :readonly="field.isOTPInstuction"
                        :label="field.isPanInstruction ? 'Enter PAN Capture text' : 'Text'"
                        v-model="field.text"
                        :ref="'instructionHeading_' + index"
                        :rules="[cannotLeaveEmpty]"
                        :error-messages="uniqueTextField(field.text)"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="11" v-show="field.isPanInstruction && field.isFaceMatchRequired">
                      <v-text-field
                        style="margin-top:-0.8rem "
                        label="Face Match Text"
                        v-model="field.faceMatchText"
                        :ref="'faceHeading_' + index"
                        :rules="[cannotLeaveEmpty]"
                        :error-messages="uniqueTextField(field.faceMatchText)"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="11" v-show="field.isPanInstruction && field.isSignatureMatchRequired">
                      <v-text-field
                        style="margin-top:-0.8rem "
                        label="Signature Match Text"
                        v-model="field.signatureMatchText"
                        :ref="'signatureHeading_' + index"
                        :rules="[cannotLeaveEmpty]"
                        :error-messages="uniqueTextField(field.signatureMatchText)"
                      ></v-text-field>
                    </v-col>
  
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="pt-0 pb-0"
                    >
                      <v-checkbox
                            v-model="field.enableScreenshot"
                            class="mt-0 mb-0"
                            @change="
                              field.isFaceMatchRequired = field.enableScreenshot
                                ? field.isFaceMatchRequired
                                : false;
                            field.isInstructionMandatory = field.enableScreenshot
                              ? field.isInstructionMandatory
                              : false;
                            "
                            label="Enable Screenshot"
                          ></v-checkbox>
                    </v-col>
  
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="pt-0 pb-0"
                    >
                      <v-checkbox
                        v-model="field.isIDCardBoxRequired"
                        class="mt-0 mb-0"
                        label="Is ID Card required?"
                      ></v-checkbox>
                    </v-col>
  
                    <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    v-if="field.enableScreenshot"
                    class="pt-0 pb-0"
                  >
                    <v-checkbox
                      v-model="field.isInstructionMandatory"
                      class="mt-0 mb-0"
                      label="Is instruction mandatory ?"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    v-if="field.enableScreenshot"
                    class="pt-0 pb-0"
                  >
                    <v-checkbox
                      v-model="field.isFaceMatchRequired"
                      class="mt-0 mb-0"
                      label="Do you want face match for this instruction?"
                      @change="() => $forceUpdate()"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    v-if="field.isPanInstruction"
                    class="pt-0 pb-0"
                  >
                    <v-checkbox
                      v-model="field.isPanExtractionRequired"
                      class="mt-0 mb-0"
                      label="Enable PAN Extraction?"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="6"
                    xl="6"
                    v-if="field.isPanInstruction"
                    class="pt-0 pb-0"
                  >
                    <v-checkbox
                      v-model="field.isSignatureMatchRequired"
                      class="mt-0 mb-0"
                      label="Enable Signature Match?"
                    ></v-checkbox>
                  </v-col>
  
                    <!-- <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                      <v-checkbox
                        v-model="field.automate"
                        class="mt-0 mb-0"
                        label="Do you want to automate this Instruction?"
                      ></v-checkbox>
                    </v-col>
  
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="field.automate">
                      <v-text-field
                        label="Please enter the wait time in seconds"
                        v-model="field.waitTime"
                      ></v-text-field>
                    </v-col>-->
                  </v-row>
                  <v-row row wrap>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="pt-0 pb-0"
                    >
                      <v-checkbox
                        v-model="field.isFeedbackRequired"
                        @change="feedbackChange(index)"
                        class="mt-0 mb-0"
                        label="Do you want realtime feedback?"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="pt-0 pb-0"
                    >
                      <!-- <v-checkbox
                        v-if="field.isScreenshotRequired"
                        v-model="field.hideWatermark"
                        class="mt-0 mb-0"
                        label="Do you want to hide watermark"
                      ></v-checkbox> -->
                    </v-col>
                  </v-row>
                  <v-row row wrap v-if="field.isFeedbackRequired">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="pt-0 pb-0"
                    >
                      <v-card
                        v-for="(item, idx) in field.feedbackFields"
                        :key="`${field.text}-${idx}`"
                        elevation="5"
                        style="margin-bottom: 25px; padding: 25px"
                      >
                        <FormBuilder
                          :ref="'FeedbackformBuilder_' + index"
                          :field="item"
                          :index="idx"
                          :fields="field.feedbackFields"
                          :variableRef="variableRef"
                          :idx = "index"
                          conditionalRendering="true"
                          @AllFieldsDeleted = "removeFeedback"
                          
                        ></FormBuilder>
                      </v-card>
                      <v-btn
                        @click="
                          localConfig.instructions[index].feedbackFields.push({
                            elementType: 'inputField',
                            isRequired: false,
                            conditionalRendering: false,
                            optionalValuesRequired: false, 
                            optionalValues: {}
                          })
                        "
                        color="primary"
                        outlined
                      >
                        <v-icon left dark>fas fa-plus</v-icon>Add Instruction
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn
                color="primary"
                fixed
                fab
                top
                right
                style="right: 50px; top: 100px"
                @click="addInstructions()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
              <div class="text-right">
                <v-btn class="ma-0 mt-2" type="submit" color="primary"
                  >Update</v-btn
                >
              </div>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import FormBuilder from "@/components/FormBuilder.vue";
  import {deepEqual} from "@/Plugins/utils.js"
  import uniqid from 'uniqid';
  export default {
    data: () => ({
      poolSequenceItems: ['sequence', 'random'],
      validFailedRef: null,
      variableRef: null,
      localConfig: {},
      currentFlow: "",
      otpLabel:"Do you want to generate random number for this instruction? \nQuestion- Please speak out {RandomNumber}",
      panLabel: "Enable PAN Question",
      poolExists: false,
      mandatoryInstructions: []
    }),
    methods: {
      addInstructions() {
        const newInstruction = {
          isIDCardBoxRequired: false,
          isSideNavRequired: false,
          enableScreenshot: false,
          text: '',
          mandatoryPool: true,
          id: uniqid()
        };
        this.localConfig.instructions.push(newInstruction);
        const newInstructions = this.localConfig.instructions.filter(i => (i.mandatoryPool || i.mandatoryPool == undefined));
        if (newInstructions.length > 0) {
          this.mandatoryInstructions = newInstructions;
        }
      },
      deleteInstruction(idx) {
        const instructionToDelete = this.localConfig.instructions[idx];
        this.localConfig.instructions.splice(idx, 1);

        if (instructionToDelete.mandatoryPool) {
            const mandatoryIdx = this.mandatoryInstructions.findIndex(
                instr => deepEqual(instr, instructionToDelete)
            );
            if (mandatoryIdx !== -1) {
                this.mandatoryInstructions.splice(mandatoryIdx, 1);
            }
        }        
      },
      removeFeedback (index){
        this.localConfig.instructions[index].isFeedbackRequired = false;
      },
      checkForOtp(field) {
        if (field.isOTPInstuction) {
          field.text = "Please speak out 123";
          field.isPanInstruction = false;
        } else {
          field.text = "";
        }
      },
      checkForPan(field) {
        if (field.isPanInstruction) {
          field.isOTPInstuction = false;
        }
        field.text = "";
      },
      feedbackChange(index) {
        if (this.localConfig.instructions[index].isFeedbackRequired) {
          this.$set(this.localConfig.instructions[index], "feedbackFields", [
            {
              elementType: "inputField",
              isRequired: false,
              conditionalRendering: false,
              optionalValuesRequired: false, 
              optionalValues: {}
            },
          ]);
        }
      },
      isWhiteSpaceEmpty() {
        let isEmpty = true;
        for (let i = 0; i < this.localConfig.instructions.length; i++) {
          if(this.localConfig.instructions[i].mandatoryPool) {
            let text = this.localConfig.instructions[i].text || null;
            let signatureText = this.localConfig.instructions[i].signatureMatchText || null;
            let faceMatchText = this.localConfig.instructions[i].faceMatchText || null;

            if (text == null || text.toString().trim().length == 0) {
              isEmpty = true;
              let refName = "instructionHeading_" + i;
              this.$refs[refName][0].focus();
              this.$refs[refName][0].blur();
              break;
            } else if(this.localConfig.instructions[i].isPanInstruction) {
              if(this.localConfig.instructions[i].isSignatureMatchRequired && (signatureText == null || signatureText.toString().trim().length == 0)) {
                isEmpty = true;
                let refName = "signatureHeading_" + i;
                this.$refs[refName][0].focus();
                this.$refs[refName][0].blur();
                break;
              } else if (this.localConfig.instructions[i].isFaceMatchRequired && (faceMatchText == null || faceMatchText.toString().trim().length == 0)) {
                isEmpty = true;
                let refName = "faceHeading_" + i;
                this.$refs[refName][0].focus();
                this.$refs[refName][0].blur();
                break;
              } else {}
            } else {
              isEmpty = false;
            }
          }
        }
        return isEmpty;
      },
      cannotLeaveEmpty(val) {
        if(!val || val.toString().trim().length === 0) {
          return "Cannot leave this empty";
        }
        return true;
      },
      displayQuestionsCheck(val) {
        if(!val || val.toString().trim().length === 0) {
          return "Cannot leave this empty";
        }
        if(val < 1 || val > this.mandatoryInstructions.length) {
            return "Value should be greater than or equal to 1 & less than total instructions";
        }
        if(val > 50) {
          return "At max 50 instructions can be displayed";
        }
        return true;
      },
      validateVariableSelector(val) {
        if(!val) {
            return "A variable needs to be selected for conditional rendering!"
        }
        return true;
      },
      uniqueTextField(val) {
        if(this.localConfig.instructions) {
          let checkerArray = this.localConfig?.instructions.filter(item => !item.isOTPInstuction).map(item => item.text);
          let duplicateElements = checkerArray.filter((element, index) => checkerArray.indexOf(element) !== index);
          if(checkerArray.indexOf(val) > -1 && duplicateElements.includes(val)){
            return "Text should be unique";
          }
        }
        return null;
      },
      feedbackFieldsChecker(check) {
        if (check === "maxLength") {
          let lengthExceeded = false;
          for (let i = 0; i < this.localConfig.instructions.length; i++) {
            if (
              this.localConfig.instructions[i].isFeedbackRequired &&
              this.localConfig.instructions[i].feedbackFields.length > 15
            ) {
              lengthExceeded = true;
              break;
            }
          }
          return lengthExceeded;
        } else if (check === "minLength") {
          let lengthMet = true;
          for (let k = 0; k < this.localConfig.instructions.length; k++) {
            if (
              this.localConfig.instructions[k].isFeedbackRequired &&
              this.localConfig.instructions[k].feedbackFields.length < 1
            ) {
              lengthMet = false;
              break;
            }
          }
          return lengthMet;
        } else if(check === "uniqueTextField") {
          let fieldCheck = false;
          let checkerArray = [];
          for (let i = 0; i < this.localConfig.instructions.length; i++) {
            if(!this.localConfig.instructions[i]?.isOTPInstuction && this.localConfig.instructions[i].mandatoryPool){
              let containerRef = "instructionHeading_" + i;
              let signatureRef = "signatureHeading_" + i;
              let faceRef = "faceHeading_" + i;
              if(checkerArray.includes(this.localConfig.instructions[i].text)){
                this.validFailedRef = this.$refs[containerRef][0];
                fieldCheck = true;
                break;
              } else {
                checkerArray.push(this.localConfig.instructions[i].text)
              }

              if(this.localConfig.instructions[i].isPanInstruction) {
                if(this.localConfig.instructions[i].isSignatureMatchRequired && checkerArray.includes(this.localConfig.instructions[i].signatureMatchText)){
                  this.validFailedRef = this.$refs[signatureRef][0];
                  fieldCheck = true;
                  break;
                } else {
                  checkerArray.push(this.localConfig.instructions[i].signatureMatchText)
                }

                if(this.localConfig.instructions[i].isFaceMatchRequired && checkerArray.includes(this.localConfig.instructions[i].faceMatchText)){
                  this.validFailedRef = this.$refs[faceRef][0];
                  fieldCheck = true;
                  break;
                } else {
                  checkerArray.push(this.localConfig.instructions[i].faceMatchText)
                }
              }
            }
          }
          return fieldCheck;
        } else {
          let fieldCheck = false;
          let variableNames = [];
          for (let m = 0; m < this.localConfig.instructions.length; m++) {
            if (this.localConfig.instructions[m].isFeedbackRequired && this.localConfig.instructions[m].mandatoryPool) {
              let containerRef = "FeedbackformBuilder_" + m;
              for (
                let j = 0;
                j < this.localConfig.instructions[m].feedbackFields.length;
                j++
              ) {
                let varRefName = "variable_" + j;
                let labRefName = "label_" + j;
                let optRefName = "options_" + j;
                if (
                  !this.localConfig.instructions[m].feedbackFields[j].variable || 
                  !this.$refs[containerRef][j].$refs[varRefName].valid
                  ) {
                  this.validFailedRef = this.$refs[containerRef][j].$refs[varRefName];
                  fieldCheck = true;
                  break;
                }
                //added validation for duplicate variable name in multple form component
                if(variableNames.includes(this.localConfig.instructions[m].feedbackFields[j].variable.trim())) {
                  fieldCheck = true;
                  this.validFailedRef = this.$refs[containerRef][j].$refs[varRefName];
                  this.variableRef = this.$refs[containerRef][j].$refs[varRefName];
                  break;
                }
                variableNames.push(this.localConfig.instructions[m].feedbackFields[j].variable.toLowerCase().trim());
                if (
                  !this.localConfig.instructions[m].feedbackFields[j].placeHolder || 
                  !this.$refs[containerRef][j].$refs[labRefName].valid
                  ) {
                  fieldCheck = true;
                  this.validFailedRef = this.$refs[containerRef][j].$refs[labRefName];
                  break;
                }
                
                if (this.localConfig.instructions[m].feedbackFields[j].conditionalRendering 
                  && !this.localConfig.instructions[m].feedbackFields[j].conditionalRenderingConditions.hasOwnProperty("variable")) {
                    fieldCheck = true;
                    this.$refs[containerRef][j].$refs[`conditionalRenderVariable_${j}`].focus();
                    this.$refs[containerRef][j].$refs[`conditionalRenderVariable_${j}`].blur();
                }

                if (this.localConfig.instructions[m].feedbackFields[j].conditionalRendering 
                  && this.localConfig.instructions[m].feedbackFields[j].conditionalRenderingConditions.hasOwnProperty("variable")) {
                  if(!this.localConfig.instructions[m].feedbackFields[j].conditionalRenderingConditions.hasOwnProperty("data") ||
                  this.localConfig.instructions[m].feedbackFields[j].conditionalRenderingConditions.data == "" ){
                      fieldCheck = true;
                      this.$refs[containerRef][j].$refs[`conditionalValue_${j}`].focus();
                      this.$refs[containerRef][j].$refs[`conditionalValue_${j}`].blur();
                  }
                }

                if (
                  this.localConfig.instructions[m].feedbackFields[j].elementType === "dropdown" || 
                  this.localConfig.instructions[m].feedbackFields[j].elementType === "multiSelectDropdown"
                ) {
                  if (
                    !this.localConfig.instructions[m].feedbackFields[j].options || 
                    this.localConfig.instructions[m].feedbackFields[j].options.length === 0 ||
                    (this.$refs[containerRef][j].$refs[optRefName] && !this.$refs[containerRef][j].$refs[optRefName].valid) 
                    ) {
                    fieldCheck = true;
                    this.validFailedRef = this.$refs[containerRef][j].$refs[optRefName];
                    break;
                  }
                  else if ( this.localConfig.instructions[m].feedbackFields[j].optionalValuesRequired) {
                    let objKeysForRef = Object.keys(this.$refs[containerRef][j].$refs).filter((key) => key.startsWith("optionalValue_"))
                    objKeysForRef.forEach((ele)=>{
                      if((/^\s+$/).test(this.$refs[containerRef][j]?.$refs[ele][0]?.value)){
                        fieldCheck = true;
                        this.validFailedRef = this.$refs[containerRef][j].$refs[ele][0];                        
                      }
                    })
                  }
                }
              }
            }
            if (fieldCheck) {
              break;
            }
          }
          return fieldCheck;
        }
      },
      highLighter(elem) {
        if(elem){
          // elem passed is a ref
          elem.focus();
          elem.blur();
        }
      },
      getFlowData() {
        this.localConfig = this.currentFlow !== "default" ? this.config.multiflow[this.currentFlow] : this.config;
        this.localConfig.introConf.mandatoryPoolSequence = this.localConfig.introConf.mandatoryPoolSequence || 'sequence';
        if(!this.mandatoryInstructions.length && this.localConfig?.instructions?.length) {
          this.mandatoryInstructions = this.localConfig.instructions.filter((i) => i.mandatoryPool || i.mandatoryPool == undefined);
        }
        this.localConfig.introConf.displayMandatoryQuestions = this.localConfig.introConf.displayMandatoryQuestions || this.mandatoryInstructions.length;
      },
      submitForm() {
        let feedbackFieldsExist = this.localConfig.instructions.some((field) => field.mandatoryPool && field.feedbackFields);
        if (this.mandatoryInstructions.length < 1) {
          eventBus.$emit("vueSnack", "Atleast 1 Instruction sets required!");
        } else if (this.isWhiteSpaceEmpty()) {
          return false;
        } else if(typeof(this.displayQuestionsCheck(this.localConfig.introConf.displayMandatoryQuestions)) == "string") {
          eventBus.$emit("vueSnack", "Display questions should be greater than or equal to 1 & less than total instructions");
        } else if (this.mandatoryInstructions.length > 50) {
          eventBus.$emit("vueSnack", "At max 50 Instruction sets permitted!");
        } else if (feedbackFieldsExist && this.feedbackFieldsChecker("maxLength")) {
          eventBus.$emit(
            "vueSnack",
            "At max 15 Feedback Instruction sets permitted!"
          );
        } else if (feedbackFieldsExist && !this.feedbackFieldsChecker("minLength")) {
          eventBus.$emit(
            "vueSnack",
            "If opted for Realtime Feedback then minimum 1 Feedback Instruction is required!!"
          );
        } else if (feedbackFieldsExist && this.feedbackFieldsChecker("uniqueTextField")) {
          this.highLighter(this.validFailedRef);
        } else if (feedbackFieldsExist && this.feedbackFieldsChecker("innerFieldsCheck")) {
          this.highLighter(this.validFailedRef);
        } else {
          this.localConfig.instructions.filter((i) => i.mandatoryPool == undefined || i.mandatoryPool).map(instruction => {
            instruction.mandatoryPool = true;
          })
          if(this.currentFlow != "default"){
            this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(this.localConfig))
          } else{
            this.config = JSON.parse(JSON.stringify(this.localConfig))
          }
          this.variableRef = null;//setting it null so that error message can we removed.
          eventBus.$emit("updateConfig", ["instructions", "introConf"]);
          eventBus.$on("updatedSuccessfully", (flag)=>{
            this.$nextTick(()=> {
              this.getFlowData();
            })
          })
        }
      },
    },
    components: {
      FormBuilder: FormBuilder,
    },
    beforeRouteUpdate (to, from, next) {
        this.currentFlow = to.params.id
        this.getFlowData()
        next()
    },
    created(){
        this.currentFlow = this.$route.params.id;
        this.getFlowData()
    },
    // updated() {
    //     setTimeout(() => {
    //       this.getFlowData()
    //     },0);
    // },
    props: ["config"],
  };
  </script>
<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-model="isValid" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Questionnaries / Instruction Sets</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-card class="elevation-5 mt-2 mb-5" hover
              v-for="(field, index) in localConfig.uvkycInstructionsSet"
              :key="index">
              <v-card-text>
                <v-row row wrap>
                  <v-col cols="11" sm="11" lg="11" xl="11">
                    <v-select v-model="field.questionType" class="mt-0 mb-0" :ref="'questionTypeInput' + index"
                        :items="questionTypeList" :id="index+'qType'" @change="onQuestionTypeChange(index)"
                        label="Please select instruction type" light>
                    </v-select>
                  </v-col>
                  <v-col cols="1" justify="right">
                    <v-btn @click="localConfig.uvkycInstructionsSet.splice(index, 1)"
                      color="red" fab small>
                      <v-icon small color="white">fas fa-trash</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11">
                        <v-text-field style="margin-top:-2.188rem " label="Enter instruction text"
                            v-model="field.text" :ref="'instructionHeading_' + index" :rules="[cannotLeaveEmpty]">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="11" v-if="field.questionType == 'Single_Choice'">
                        <v-combobox v-model="field.selectionChoicesForRadio" multiple chips persistent-hint
                            hint="Add Options (Type option name and press 'Enter')" :rules="[validateOptions]"
                            :ref="'instructionRadioChoices_' + index" :error-messages="triggerError(`instructionRadioChoices_${index}`)"
                            v-on:focus="handleTouch">
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" 
                        v-if="field.questionType != 'OTP' && field.questionType != 'Single_Choice' && field.questionType != 'Input_Text'">
                        <v-checkbox v-model="field.enableScreenshot"
                            class="mt-0 mb-0" label="Enable Screenshot">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.IdClassificationAPI"
                            class="mt-0 mb-0" label="Is ID classification required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.isOCRRequired"
                            class="mt-0 mb-0" label="Is OCR required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.isFacematchRequired"
                            class="mt-0 mb-0" label="Is live facematch required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.isForgeryCheckRequired"
                            class="mt-0 mb-0" label="Is forgery check required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.isPANSignatureExtractionRequired"
                            class="mt-0 mb-0" label="Is PAN signature extraction required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0" v-if="field.questionType == 'PAN_Widget'">
                        <v-checkbox v-model="field.isPANFaceExtractionRequired"
                            class="mt-0 mb-0" label="Is PAN face extraction required?">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                        <v-checkbox v-model="field.isMandatory"
                            class="mt-0 mb-0" label="Is instruction mandatory?">
                        </v-checkbox>
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn fixed fab top right style="right: 50px; top: 100px" color="primary"
              @click="addNewInstruction()">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <div class="text-right">
              <v-btn class="ma-0 mt-2" type="submit" color="primary" :disabled="!isValid">
                Update
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    validFailedRef: null,
    variableRef: null,
    localConfig: {},
    currentFlow: "",
    touched: false,
    isValid: true,
    questionTypeList: ['OTP', 'PAN_Widget', 'Single_Choice', 'Image_Capture', 'Input_Text'],
  }),
  methods: {
    isWhiteSpaceEmpty() {
      let isEmpty = true;
      for (let i = 0; i < this.localConfig.uvkycInstructionsSet.length; i++) {
        let text = this.localConfig.uvkycInstructionsSet[i].text;
        if (text == null || text.toString().trim().length == 0) {
          isEmpty = true;
          let refName = "instructionHeading_" + i;
          this.$refs[refName][0].focus();
          this.$refs[refName][0].blur();
          break;
        } else {
          isEmpty = false;
        }
      }
      return isEmpty;
    },
    validateOptions(val) {
      if (!val || !val.length) {
        this.isValid = false;
        return "At least two option is required";
      }

      if (val && val.length && val.length > 4) {
        this.isValid = false;
        return "Maximum of four options allowed";
      }

      if (val && val.length && val.length <= 1) {
        this.isValid = false;
        return "At least two options are required";
      }

      for (let i = 0; i < val.length; i++) {

        if (val[i].match(/[()~`<>*]/)) {
          return "Cannot take  ( ) ~ ` < > * in input";
        }
        if (!val[i].replace(/\s/g, '').length) {
          return "Only spaces not allowed";
        }
      }
      this.touched = false;
      return true;
    },
    handleTouch(){
      this.touched = true;
    },
    triggerError(val){
      if(this.touched && this.$refs[val] && this.$refs[val].lazyValue && this.$refs[val].lazyValue.length 
        && this.$refs[val].lazyValue.length <= 1){
        return "At least two option is required";
      }
      if(this.touched && this.$refs[val] && this.$refs[val].lazyValue && this.$refs[val].lazyValue.length 
        && this.$refs[val].lazyValue.length > 4){
        return "Maximum of four options are allowed";
      }
      return null;
    },
    cannotLeaveEmpty(val) {
      if(!val || val.toString().trim().length === 0) {
        return "Cannot leave this empty";
      }
      return true;
    },
    highLighter(elem) {
      if(elem){
        // elem passed is a ref
        elem.focus();
        elem.blur();
      }
    },
    getFlowData(){
      if(this.currentFlow != "default"){
         this.localConfig = this.config.multiflow[this.currentFlow];
      } else{
        this.localConfig = this.config;
      }
       
    },
    addNewInstruction() {
      this.localConfig.uvkycInstructionsSet.push({questionType: '',text: '',enableScreenshot: false});
    },
    onQuestionTypeChange(index) {
        this.localConfig.uvkycInstructionsSet[index].text = '';
    },
    submitForm() {
      let otpQuestionCount = 0;
      let panQuestionCount = 0;
      if(this.localConfig.uvkycInstructionsSet.length) {
        this.localConfig.uvkycInstructionsSet.forEach((item) => {
          if(item.questionType == 'OTP'){
            otpQuestionCount = otpQuestionCount+1;
          }
          if(item.questionType == 'PAN_Widget'){
            panQuestionCount = panQuestionCount + 1;
          }
        })
      }


      if ( this.localConfig.uvkycInstructionsSet.length <= 2) {
        eventBus.$emit("vueSnack", "Atleast 3 Instruction sets required!");
      } else if (this.isWhiteSpaceEmpty()) {
        return false;
      } else if (this.localConfig.uvkycInstructionsSet.length > 20) {
        eventBus.$emit("vueSnack", "At max 20 Instruction sets permitted!");
      } else if(otpQuestionCount > 1) {
        eventBus.$emit("vueSnack", "Only one OTP question can be configured during journey!");
      } else if(panQuestionCount > 1) {
        eventBus.$emit("vueSnack", "Only one PAN widget question can be configured during journey!");
      } else {
        this.variableRef = null;//setting it null so that error message can we removed.
        eventBus.$emit("updateConfig", ["uvkycInstructionsSet"]);
        eventBus.$on("updatedSuccessfully", (flag)=>{
          this.$nextTick(()=> {
            this.getFlowData();
          })
        })
        
      }
    },
  },
  beforeRouteUpdate (to, from, next) {
      this.currentFlow = to.params.id
      this.getFlowData()
      next()
  },
  created(){
      this.currentFlow = this.$route.params.id;
      this.getFlowData()
  },
  updated() {
      setTimeout(() => {
        this.getFlowData()
      },0);
  },
  props: ["config"],
};
</script>
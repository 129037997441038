<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Skill Sets</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-combobox
              v-model="config.skillSets"
              multiple
              chips
              hint="Add Skills (Type skill name and press 'Enter')"
              persistent-hint
            ></v-combobox>
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    submitForm() {
      eventBus.$emit("updateConfig", ["skillSets"]);
    }
  },
  props: ["config"]
};
</script>
let axiosInstance = window.axiosInstance;
import { end_points } from "@/config";

const getSkills = (props) => {
    return new Promise (async(resolve, reject) => {
        if (props && props.customerId) {
            let skillSets = [];
            let hasSkills = false;
            await axiosInstance.post(end_points.getSkills(props.customerId), {
                customerId: props.customerId
              }).then((res) => {
                if (res && res.data) {
                    hasSkills = res.data.hasSkills ? res.data.hasSkills : false;
                    skillSets = hasSkills ? res.data.skillList ? res.data.skillList : [] : [];
                    resolve({
                        success: true,
                        skillSets: skillSets,
                        hasSkills: hasSkills
                    })
                } else {
                    console.log("Error in Response");
                    reject({
                        success: false,
                        skillSets: skillSets,
                        hasSkills: hasSkills
                    })
                }
              }).catch((err) => {
                console.log("skills.flux: Error in getting skills", err);
                reject({
                    success: false,
                    skillSets: skillSets,
                    hasSkills: hasSkills
                })
              })
        } else {
            console.log("Can't get skills, customerId is Missing.")
            reject({
                success: false,
                reason: "Customer Id Missing"
            })
        }
    })
}



export {
    getSkills
}
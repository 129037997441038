<template>
  <v-container>
    <div v-if="callData.confSession">
      <p class="headline">{{callData.confSession.meetingName}}</p>
      <v-card class="mt-2 mb-5">
        <v-card-text>
          <span class="title">Users</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="ml-5" v-for="user in callData.confSession.users" :key="user.email">
            <div class="subtitle-1">
              <b>Name:</b>
              {{user.name}}
            </div>
            <div class="subtitle-1">
              <b>Email:</b>
              {{user.email}}
            </div>
          </div>
          <v-divider color="signzy_color" class="ml-5 mr-5 mt-2 mb-2" />
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="callData.output && callData.output.confDataCb.data && callData.output.confDataCb.data.feedback"
      >
        <v-card-text>
          <span class="title">Feedback</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="subtitle-1 ml-5" v-if="callData.output.confDataCb.data.feedback">
            <b>Ratings:</b>
            {{callData.output.confDataCb.data.feedback}}
          </div>
          <div class="subtitle-1 ml-5" v-if="callData.output.confDataCb.data.feedbackMessage">
            <b>Feedback:</b>
            {{callData.output.confDataCb.data.feedbackMessage}}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="callData.adminFeedback && (callData.adminFeedback.comments || callData.adminFeedback.rating)"
      >
        <v-card-text>
          <span class="title">Organizer Feedback</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="subtitle-1 ml-5" v-if="callData.adminFeedback.rating">
            <b>Ratings:</b>
            {{callData.adminFeedback.rating}}
          </div>
          <div class="subtitle-1 ml-5" v-if="callData.adminFeedback.comments">
            <b>Feedback:</b>
            {{callData.adminFeedback.comments}}
          </div>
          <div class="subtitle-1 ml-5" v-if="callData.adminFeedback.improvement">
            <b>Improvements:</b> {{improvements}}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="callData.output && callData.output.confDataCb.data && callData.output.confDataCb.data.recordedVideoData && callData.output.confDataCb.data.recordedVideoData.videoUrl && !videoDownloadStarted"
      >
        <v-card-text>
          <span class="title">Recorded Video</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <video
            controls
            controlsList="nodownload"
            :src="videoData"
            preload="none"
            width="100%"
            :poster="callData.output.confDataCb.data.recordedVideoData.videoThumbnail"
          >
            <source
              type="video/mp4"
            />
          </video>
          <div class="text-center" v-if="!videoUrl">
            <p>No Recording data found!</p>
          </div>
          <div class="text-center" v-if="callData.output && callData.output.confDataCb.data && callData.output.confDataCb.data.recordedVideoData && callData.output.confDataCb.data.recordedVideoData.videoUrl && videoDownloadStarted">
            <div class="bar">
              <i class="sphere"></i>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-2 mb-5">
        <v-card-text>
          <span class="title">Audit Remarks</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <v-text-field class="ml-5 mt-5 mb-0 pa-0 mr-5" label="Remarks" v-model="comments" filled></v-text-field>
          <div class="text-center">
            <v-btn dark rounded color="primary" @click="updateAudit()">Mark as completed</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-card
        class="mt-2 mb-5"
        v-if="createSessionResp && createSessionResp.users && createSessionResp.users.length!=0"
      >
        <v-card-text>
          <span class="title">User details</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="subtitle-1 ml-5">
            <b>Name:</b>
            {{createSessionResp.users[0].name}}
          </div>
          <div class="subtitle-1 ml-5">
            <b>Email:</b>
            {{createSessionResp.users[0].email}}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="sessionData && sessionData.users && sessionData.users.length!=0 && sessionData.users[0].callDuartion"
      >
        <v-card-text>
          <span class="title">Call duration</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="subtitle-1 ml-5">
            <b>Start Time:</b>
            {{new Date(parseInt(sessionData.users[0].callDuartion.startTime))}}
          </div>
          <div class="subtitle-1 ml-5">
            <b>End Time:</b>
            {{new Date(parseInt(sessionData.users[0].callDuartion.endTime))}}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="createSessionResp && createSessionResp.users && createSessionResp.users.length!=0"
      >
        <v-card-text>
          <span class="title">Documents Uploaded</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <v-row
            v-for="document in createSessionResp.users[0].documents"
            :key="document.documentName"
          >
            <v-col>
              <span class="title">{{document.documentName}}</span>
              <div v-for="(value, name) in document.data" v-bind:key="name">
                <span v-if="value.length>0">
                  <b>{{ name.toUpperCase() }}</b>
                  :
                  {{ value }}
                </span>
              </div>
            </v-col>
            <!-- <v-col cols="12" md="4" sm="12" lg="4" xl="4" v-if="document.image">
              <img :src="document.image" style="width:100%" />
            </v-col>-->
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="sessionData && sessionData.users && sessionData.users.length!=0 && sessionData.users[0].geo"
      >
        <v-card-text>
          <span class="title">Geo Tagging Data</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <v-row>
            <v-col cols="12" md="5" sm="12" lg="5" xl="5">
              <div class="subtitle-1">
                <b>City:</b>
                {{sessionData.users[0].geo.city}}
              </div>
              <div class="subtitle-1">
                <b>Region:</b>
                {{sessionData.users[0].geo.regionName}}
              </div>
              <div class="subtitle-1">
                <b>IP:</b>
                {{sessionData.users[0].geo.ipAddress}}
              </div>
              <div class="subtitle-1">
                <b>Latitude:</b>
                {{sessionData.users[0].geo.latitude}}
              </div>
              <div class="subtitle-1">
                <b>Longitude:</b>
                {{sessionData.users[0].geo.longitude}}
              </div>
              <div class="subtitle-1" v-if="sessionData.users[0].geo.complete">
                <b>Complete Address:</b>
                <br />
                {{sessionData.users[0].geo.complete}}
              </div>
            </v-col>
            <v-col cols="12" md="7" sm="12" lg="7" xl="7">
              <iframe
                height="200"
                width="100%"
                frameborder="0"
                style="border:0"
                :src="getLatLon(sessionData.users[0].geo.latitude,sessionData.users[0].geo.longitude)"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="sessionData && sessionData.users && sessionData.users.length!=0 && sessionData.users[0].browserData"
      >
        <v-card-text>
          <span class="title">User Browser Data</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div class="subtitle-1 ml-5">
            <b>Browser Name:</b>
            {{sessionData.users[0].browserData.browserName}}
          </div>
          <div class="subtitle-1 ml-5">
            <b>Browser Version:</b>
            {{sessionData.users[0].browserData.browserVersion}}
          </div>
          <div class="subtitle-1 ml-5">
            <b>Host OS:</b>
            {{sessionData.users[0].browserData.os}}
          </div>
          <div class="subtitle-1 ml-5">
            <b>User Agent:</b>
            {{sessionData.users[0].browserData.userAgent}}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="sessionData && sessionData.users && sessionData.users.length!=0 && sessionData.users[0].fieldValues"
      >
        <v-card-text>
          <span class="title">VCIP Admin remarks</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <div v-for="(value, name) in sessionData.users[0].fieldValues" v-bind:key="name">
            <div class="subtitle-1 ml-5" v-if="value.length>0">
              <b>{{ name.toUpperCase() }}</b>
              :
              {{ value }}
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-2 mb-5" v-if="screenshotImages.length!=0">
        <v-card-text>
          <span class="title">Images captured</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <v-carousel :show-arrows="false" cycle>
            <v-carousel-item
              v-for="item in screenshotImages"
              :key="item.image"
              :src="item.image"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <div class="text-center instructionPanel subtitle-1 pt-3 pb-3">{{item.instruction}}</div>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
      <v-card
        class="mt-2 mb-5"
        v-if="sessionData && sessionData.users && sessionData.users.length!=0 && sessionData.users[0].recordedVideoData"
      >
        <v-card-text>
          <span class="title">Recorded Video</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <video
            controlsList="nodownload"
            v-if="sessionData.users[0].recordedVideoData.videoUrl && videoData && !videoDownloadStarted"
            controls
            preload="none"
            width="100%"
            :poster="sessionData.users[0].recordedVideoData.videoThumbnail"
            :src="videoData"
          >
            <source  type="video/mp4" />
          </video>
          <div class="text-center" v-if="!sessionData.users[0].recordedVideoData.videoUrl">
            <p>No Recording data found!</p>
          </div>
          <div class="text-center" v-if="sessionData.users[0].recordedVideoData.videoUrl && videoDownloadStarted">
            <div class="bar">
              <i class="sphere"></i>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-2 mb-5">
        <v-card-text>
          <span class="title">Audit Remarks</span>
          <v-divider class="headlinemark mb-2 signzy_color" />
          <v-text-field
            class="ml-5 mt-5 mb-0 pa-0 mr-5"
            label="Remarks"
            v-model="comments"
            :disabled="status=='completed'"
            filled
          ></v-text-field>
          <div class="text-center" v-if="status!='completed'">
            <v-btn dark rounded color="primary" @click="updateAudit()">Mark as completed</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { vcip_end_points, end_points } from "@/config";
import { downloadFile } from "@/flux/persist.flux";
import ViewAudit from "./ViewAudit";
export default {
  data: () => ({
    comments: "",
    createSessionResp: {},
    sessionData: {},
    screenshotImages: [],
    improvements: "",
    videoData: null,
    videoDownloadStarted: false
  }),
  components: {
    ViewAudit
  },
  methods: {
    async updateAudit() {
      try {
        let res = await axiosInstance.post(
          end_points.update_audit(this.callData._id),
          {
            isAudited: true,
            onboardingStatus: "",
            auditFeedback: this.comments
          }
        );
        eventBus.$emit("vueSnack", "Successfully submitted!");
        eventBus.$emit(
          this.status == "completed"
            ? "loadCompletedAudit"
            : "loadPendingAudit",
          ""
        );
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    },
    getLatLon(lat, lon) {
      return end_points.maps_embed(lat, lon, this.$store.getters.envValues.googleMapsApiKey);
    },
    async getSessionData(sessionId) {
      let sessionResponse = await axiosInstance.get(
        end_points.getCall_SessionDetails(sessionId)
      );
      if(sessionResponse && sessionResponse.data && sessionResponse.data.data){
          this.sessionData = sessionResponse.data.data;
      }else{
        this.sessionData = {};
      }
    }
  },
  async created() {
    if (this.callData.output && this.callData.output.createSessionResp) {
      this.createSessionResp = this.callData.output.createSessionResp.result;
      await this.getSessionData(
        this.callData.output.createSessionResp.result.sessionId
      );
      if (this.status == "completed") {
        this.comments = this.callData.auditFeedback;
      }
      if (
        this.sessionData &&
        this.sessionData.users &&
        this.sessionData.users.length != 0 &&
        this.sessionData.users[0].screenshots &&
        this.sessionData.users[0].instructions
      ) {
        this.sessionData.users[0].instructions.forEach(async(instruction, index) => {
          if (this.sessionData.users[0].screenshots[index]["finalImage"]) {
            // this.sessionData.users[0].screenshots[index]["finalImage"]
            let respImg = await downloadFile(this.sessionData.users[0].screenshots[index]["finalImage"]);
            if (respImg.status === 200) {
              this.screenshotImages.push({
                instruction: instruction.text,
                image: `data:${respImg.headers["content-type"].split(";")[0]};base64, ${respImg.data.file}`
              });
            } 
          }
        });
      } else {
        this.screenshotImages = [];
        this.improvements = "";
      }
      if (this.sessionData.users[0].recordedVideoData.videoUrl) {
        this.videoDownloadStarted = true;
        let respVideo = await downloadFile(this.sessionData.users[0].recordedVideoData.videoUrl);
        if (respVideo.status === 200) {
          this.videoData = `data:${respVideo.headers["content-type"].split(";")[0]};base64, ${respVideo.data.file}`;
          this.videoDownloadStarted = false;
        }
      }
    } else {
      this.createSessionResp = {};
      this.sessionData = {};
      this.improvements = "";
      this.screenshotImages = [];

      if (
        this.callData &&
        this.callData.adminFeedback &&
        this.callData.adminFeedback.improvement
      ) {
        this.improvements += this.callData.adminFeedback.improvement
          .rescheduling
          ? "Scheduling, "
          : "";
        this.improvements += this.callData.adminFeedback.improvement.ux
          ? "User experience"
          : "";
        this.improvements +=
          !this.callData.adminFeedback.improvement.ux &&
          !this.callData.adminFeedback.improvement.rescheduling
            ? "N/A"
            : "";
      }
      if (this.callData && this.callData.output && this.callData.output.confDataCb && this.callData.output.confDataCb.data && this.callData.output.confDataCb.data.recordedVideoData && this.callData.output.confDataCb.data.recordedVideoData.videoUrl) {
        this.videoUrl = this.callData.output.confDataCb.data.recordedVideoData.videoUrl;
        this.videoDownloadStarted = true;
        let respVideo = await downloadFile(this.callData.output.confDataCb.data.recordedVideoData.videoUrl);
        if (respVideo.status === 200) {
          this.videoData = `data:${respVideo.headers["content-type"].split(";")[0]};base64, ${respVideo.data.file}`;
          this.videoDownloadStarted = false;
        }
      }
    }
  },
  props: ["callData", "status"]
};
</script>

<style scoped>
.headlinemark {
  width: 40%;
}
.instructionPanel {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  color: white;
}
.bar {
	/* Size and position */
    font-size: 20px; /* 1em */
    width: 10em;
    height: 1em;
    position: relative;
    margin: 100px auto;

    /* Styles */
    border-radius: .5em; /* Height/2 */
    background: white; /* Fallback */
    background: rgba(255,255,255,0.6);
    box-shadow: 
        0 0 0 .05em rgba(100,100,100,0.075), /* Subtle border */
        0 0 0 .25em rgba(0,0,0,0.1),		   /* Outter border */
        inset 0 .1em .05em rgba(0,0,0,0.1),   /* Inset shadow */
        0 .05em rgba(255,255,255,0.7);	   /* Slight reflection */
}
.bar:after {
	/* Content and position */
    content: "Please wait while the video is being loaded.";
    position: absolute;
    left: 5%;
    top: 150%;

    /* Font styles */
    font-family: 'Carrois Gothic', sans-serif;
    font-size: 14px;
    color: #555;
    text-shadow: 0 .05em rgba(255,255,255,0.7);
}
.sphere {
    /* Size */
    display: block;
    width: 1em;
    height: 100%;
    
    /* Styles */
    border-radius: 50%;
    background: linear-gradient(#eee, #ddd);
    box-shadow:
        inset 0 .15em .1em rgba(255,255,255,0.3), /* Top light */
        inset 0 -.1em .15em rgba(0,0,0,0.15),	   /* Bottom shadow */
        0 0 .25em rgba(0,0,0,0.3);			   /* Outter shadow */

    /* Animation */
    animation: move 1.75s ease-in-out infinite alternate;
}
@keyframes move {
    to { margin-left: 90%; }
}
</style>
<template>
  <div>
    <viewAudit v-if="showAudit" />
    <router-view v-else />
  </div>
</template>


<script>
import viewAudit from "./ViewAudit";
export default {
  data: () => ({
    showAudit: false
  }),
  components: {
    viewAudit
  },
  created() {}
};
</script>

<style>
</style>
<template>
  <v-row row wrap>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
      <v-select v-model="field.elementType" :items="fieldTypes" label="Element Type"></v-select>
    </v-col>
    <v-col cols="11" sm="11" md="5" lg="5" xl="5" class="pt-0 pb-0">
      <v-text-field
        label="Variable name"
        v-model="field.variable"
        :rules="[validateVariableName]"
        :error-messages="getError"
        validate-on-blur
        required
        :ref="'variable_'+index"
      ></v-text-field>
    </v-col>
    <v-col cols="1">
      <v-btn fab color="red" small @click="deleteVariableRoots(index)">
        <v-icon small color="white">fas fa-trash</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-0 pb-0">
      <v-text-field
        label="Label"
        v-model="field.placeHolder"
        :rules="[validateLabel]"
        :ref="'label_'+index"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12" sm="12" md="11" lg="11" xl="11"
      class="pt-0 pb-0"
      v-if="field.elementType === 'dropdown' || field.elementType === 'multiSelectDropdown'"
    >
      <v-combobox v-if="typeof field.totalOptions == 'undefined'"
        v-model="field.options"
        multiple
        chips
        hint="Add Options (Type option name and press 'Enter')"
        persistent-hint
        :rules="[validateOptions]"
        :ref="'options_'+index"
        :error-messages="this.triggerError(`options_${index}`)"
        v-on:focus="handleTouch"
        @blur="updateOptions"
      ></v-combobox>
      <!-- <v-text-field v-if="typeof field.totalOptions == 'string'"
        :readonly="true"
        :ref="'options_'+index"
        v-model="field.totalOptions"
      >
      </v-text-field>-->
      <v-chip-group
        v-if="typeof field.totalOptions == 'string'"
        column                 
      >
        <v-chip
          v-for="value in field.options"
          :key="value"
        >
          {{ value }}
        </v-chip>
      </v-chip-group>
      <v-divider style ="border-color: gray" v-if="typeof field.totalOptions == 'string'"></v-divider>
      </v-col> 
      <v-col cols="11" sm="11" md="1" lg="1" xl="1" class="pt-0 pb-0"
      v-if="field.elementType === 'dropdown' || field.elementType === 'multiSelectDropdown'"
      >
        <v-file-input
        hide-input
        clearable
        hint="Only .csv files are allowed, with 50000 records"
        truncate-length="22"
        @click="resetFileUpload(index)"
        @change="getOptions"
        accept=".csv"
        :ref="'dropDownOptions_'+index"
        >
      </v-file-input> 
    </v-col>
    <v-col 
      cols="12" 
      class="pt-0 pb-0"
      v-if="field.elementType === 'dropdown' || field.elementType === 'multiSelectDropdown'"
    >
      <v-checkbox
        v-model="field.optionalValuesRequired"
        color="primary"
        label="Do you want to add optional values?"
      ></v-checkbox>
      <span 
        v-if="field.optionalValuesRequired"
        style="color: rgba(0, 0, 0, 0.6); "
      >
        Note: If no value is entered, then default option values will be used.
      </span>
    </v-col>
    <v-col 
      cols="9" 
      class="pa-0"
      v-if="(field.elementType === 'dropdown' || field.elementType === 'multiSelectDropdown') && field.optionalValuesRequired"
    >
      <v-container fluid>
        <v-select v-if="typeof field.totalOptions == 'string'"
        v-model="field.optionalValuesDrop"
        :items="field.options"
        :id="typeof field.optionsValue == 'object'? field.optionsValue[0].typeId: field.optionsValue"
        item-text="text"
        @focus="setTypeId"
        @blur="setOptions"
        class="i_am_feedback_dropdown"
        item-value="value"
        chips
        multiple >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field @blur="setOptions" v-model="searchTerm" placeholder="Search" @input="search"></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      <v-row
        style="padding: 0 25px"
        v-for="(options) in (typeof field.totalOptions == 'undefined' ? field.options: field.optionalValuesDrop)"
        :key="options"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
          style="display: flex; align-items: center"
        >
          <span>{{options}} : </span>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          v-if="field.optionalValuesRequired"
          class="pt-0 pb-0"
        >
          <v-text-field 
            label="Value"
            :ref="'optionalValue_'+options"
            :rules="[validateOptionalValueText]"
            v-model="field.optionalValues[options]"
            ></v-text-field>
        </v-col>
      </v-row>
      </v-container>
    </v-col>
    <v-col
      cols="12" sm="12" md="11" lg="11" xl="11"
      class="pt-0 pb-0"
      v-if="field.elementType === 'radio'"
    >
      <v-combobox v-if="typeof field.totalOptions == 'undefined'"
          v-model="field.options"
          multiple
          chips
          hint="Add Options (Type option name and press 'Enter')"
          persistent-hint
          :rules="[validateRadioOptions]"
          :ref="'options_'+index"
          :error-messages="this.triggerError(`options_${index}`)"
          v-on:focus="handleTouch"
          @blur="updateOptions"
      ></v-combobox>
    </v-col>
    <v-col cols="12" class="pt-0 pb-0">
      <v-checkbox
        v-if="!field.conditionalRendering"
        v-model="field.isRequired"
        color="primary"
        label="Is Required?"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" v-if="index!=0 && !realtimeFeedback" class="pt-0 pb-0">
      <v-checkbox
        v-model="field.conditionalRendering"
        color="primary"
        label="Conditional Rendering Required?"
        @change="updateConditionalRendering(field.conditionalRendering,index)"
      ></v-checkbox>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="6"
      xl="6"
      v-if="field.conditionalRendering"
      class="pt-0 pb-0"
    >
      <v-select
        v-model="field.conditionalRenderingConditions.variable"
        :ref="'conditionalRenderVariable_'+index"
        :items="variables.filter((el, idx)=> idx!== index)"
        label="Variable"
        :rules="[validateVariableSelector]"
        :error-messages="this.triggerConditionalRenderingError(`conditionalRenderVariable_${index}`)"
        v-on:focus="condVarTouched = true"
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="6"
      xl="6"
      v-if="field.conditionalRendering"
      class="pt-0 pb-0"
    >
      <v-text-field 
        label="Value" 
        v-model="field.conditionalRenderingConditions.data" 
        :rules="[validateValue]"
        :ref="'conditionalValue_' +index"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {base_url, end_points} from "@/config";
let axiosInstance = window.axiosInstance;
export default {
  data: () => ({
    fieldTypes: [
      { value: "multiSelectDropdown", text: "Multi-Select dropdown" },
      { value: "dropdown", text: "Dropdown" },
      { value: "inputField", text: "Input Text" },
      { value: "textarea", text: "TextArea" },
      { value: "number", text: "Numeric" },

    ],
    isValid: true,
    dropdownOptions: null,
    searchTerm: "",
    typeId: null,
    touched: false,
    condVarTouched: false
  }),
  created() {
    if (this.parentComponent === 'adminForm') {
      this.fieldTypes.push({ value: "radio", text: "Radio Question" });
    }
  },  
  methods: {
    updateConditionalRendering(flag,index){
    if(flag){
      this.field.conditionalRenderingConditions={variable:"",data:""};
      }
     else{
      this.field.conditionalRenderingConditions={};
     }
     this.field.isRequired=this.field.conditionalRendering?false:this.field.isRequired;

    },
    setTypeId(reference) {
      this.typeId = reference.target.id;
      this.searchTerm = "";
      if(typeof this.field.optionsValue == 'string') {
        this.search("");
      }
    },
    setOptions() {
      this.field.options = this.field.options.concat(this.field.optionalValuesDrop);
      if(typeof this.field.optionsValue == 'object') {
        this.search("");
      }
    },
    async search(val) {
      try {
          if(typeof this.field.optionsValue == 'object') {
            let options = []
            this.field.optionsValue.map(item => {
              if(item.value.toLowerCase().includes(val)) {
                options.push(item.value)
              }
            });
            this.field.options = options;
            const isRequired = this.field.optionalValuesRequired;
            this.field.optionalValuesRequired = !isRequired;
            this.field.optionalValuesRequired = isRequired;
          } else {
            const response = await axiosInstance.get(base_url + end_points.dropDownFields + this.typeId + "?value=" + this.searchTerm +"&perPage=100");
            let dropDownValues = response.data.data;
            if(response.headers["totalpages"] && response.headers["totalpages"] > 1) {
              for(let i = 2; i<=response.headers["totalpages"]; i++) {
                if(this.searchTerm != val) {
                  dropDownValues=[];
                  break;
                }
                const values = await axiosInstance.get(base_url + end_points.dropDownFields + this.typeId + "?value=" + val + "&pageNumber=" + i +"&perPage=100");
                dropDownValues = dropDownValues.concat(values.data.data);
              }
            }
            if(this.searchTerm != val) {
              return;
            }
            this.field.options = dropDownValues;
          }
      } catch (error) {
        console.log("Something went wrong while searching ::::", error);
      }
    },
    async updateOptions() {
      const typeId = typeof this.field.optionsValue == "object" ? null : this.field.optionsValue;
      const options = await axiosInstance.post(base_url + end_points.csvValidator, {data: this.field.options, typeId: typeId});
      if(options && options.data && options.data.data) {
        this.field.options = options.data.values;
        this.field.optionsValue = options.data.data;
      } else {
        eventBus.$emit("vueSnack", options.data.message || "Something went wrong !");
      }
    },
    handleTouch(){
      this.touched = true
    },
    triggerError(val){
      if(this.touched && this.$refs[val].lazyValue.length == 0){
        return "At least one option is required";
      }
      return null;
    },
    triggerConditionalRenderingError(val){
      if(this.condVarTouched && !this.$refs[val]?.lazyValue){
        return "A variable needs to be selected for conditional rendering!"
      }
      return null;
    },
    deleteVariableRoots(index){
      let checkVariableToDel = [] //maintain a list of linked variable at different levels
      if(this.fields.length == 1) {
        this.$emit('AllFieldsDeleted', this.idx)
      }
      checkVariableToDel.push(this.fields[index].variable);
      this.fields.splice(index, 1)
      for(let i=0;i<this.fields.length;i++){
        if( this.fields[i].conditionalRendering && 
            this.fields[i].conditionalRenderingConditions &&
            checkVariableToDel.includes(this.fields[i].conditionalRenderingConditions.variable)
          ){
          checkVariableToDel.push(this.fields[i].variable);
          this.fields.splice(i, 1)
          i--;
        }
      }
      // eventBus.$emit("vueSnack", checkVariableToDel[0]+ " deleted with its sub " + checkVariableToDel.join(" ").slice(1,checkVariableToDel.length));
    },
    resetFileUpload(index){
      this.$refs[`dropDownOptions_${index}`].clearableCallback()
    },
    async getOptions(file) {
      const supportedMimeTypes = ["text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
      
        if (file && file.size < 14000000 && supportedMimeTypes.includes(file.type)) {
          try {
            const typeId = typeof this.field.optionsValue == "object" || typeof this.field.optionsValue == "undefined" ? null : this.field.optionsValue;
            let formData = new FormData();
            formData.append("file", file);
            formData.append("typeId", typeId);
            eventBus.$emit('vueLoader', true);
            const options = await axiosInstance.post(base_url + end_points.csvValidator, formData);
            eventBus.$emit('vueLoader', false);
            if(options && options.data && options.data.data) {
              this.field.options = options.data.values;
              this.field.optionsValue = options.data.data;
              this.field.totalOptions = options.data.data.length + " options available";
              const isRequired = this.field.optionalValuesRequired;
              this.field.optionalValuesRequired = !isRequired;
              this.field.optionalValuesRequired = isRequired;
            } else {
              eventBus.$emit("vueSnack", options.data.message || "Something went wrong !");
            }
          } catch (e) {
            eventBus.$emit('vueLoader', false);
            console.log("Error while uploading a file");
            eventBus.$emit("vueSnack", "Error while uploading file !");
          }
        } else {
          console.log('file size ::::::::', file.size);
          if(file && file.size > 14000000) {
            eventBus.$emit("vueSnack", "File should be less than 14 MB");
          } else if(!supportedMimeTypes.includes(file.type)) {
            eventBus.$emit("vueSnack", "Only CSV files with no header are allowed !");
          } else {
            eventBus.$emit("vueSnack", "Something went wrong, Please try again !");
          }
        }
    },
    validateVariableName(val) {
      if (!val || !val.length) {
        return "Variable name can't be left empty";
      }

      if (/^[0-9]/.test(val)) {
        this.isValid = false;
        return "Variable name can't start with a number";
      }
      if (!/^[a-zA-Z0-9_]+$/.test(val)) {
        return "Invalid Characters Present (alphanumeric only)";
      }
      if (val.match(/[()~`<>*]/)) {
        return "Cannot take  ( ) ~ ` < > * in input";
      }

      let counter = 0;
      for (let i = 0; i < this.fields.length; i++) {
        let v = this.fields[i].variable;
        if (v == val) counter++;
        if (counter > 1) {
          this.isValid = false;
          return "This variable already exists";
        }
      }

      this.isValid = true;
      return true;
    },
    validateOptionalValueText(val){
      if (val && !val.replace(/\s/g, '').length) {
          return "Only spaces not allowed";
      }
      if (val && val.match(/[()~`<>*]/)) {
        return "Cannot take  ( ) ~ ` < > * in input";
      }

      return true;
    },
    validateVariableSelector(val) {
      if(!val) {
        return "A variable needs to be selected for conditional rendering!"
      }
      return true;
    },
    validateOptions(val) {
      if (!val || !val.length) {
        this.isValid = false;
        return "At least one option is required";
      }

      for (let i = 0; i < val.length; i++) {

        if (val[i].match(/[()~`<>*]/)) {
          return "Cannot take  ( ) ~ ` < > * in input";
        }
        if (!val[i].replace(/\s/g, '').length) {
          return "Only spaces not allowed";
        }
      }
      this.touched = false;
      return true;
    },

    validateRadioOptions(val) {
      if (!val || !val.length || val.length < 2) {
        this.isValid = false;
        return "At least two option is required";
      }

      if(val.length > 4) {
        this.isValid = false;
        return "Maximum of four options are allowed";
      }

      for (let i = 0; i < val.length; i++) {

        if (val[i].match(/[()~`<>*]/)) {
          return "Cannot take  ( ) ~ ` < > * in input";
        }
        if (!val[i].replace(/\s/g, '').length) {
          return "Only spaces not allowed";
        }
      }
      this.touched = false;
      return true;
    },

    validateLabel(val) {
      if (!val || !val.length) {
        return "Label can't be left empty";
      }
      if (val && !val.replace(/\s/g, '').length) {
          return "Only spaces not allowed";
      }
      if (val && val.length>200) {
        return "Label size too large, must be less than 200 characters";
      }
      if (val.match(/[()~`<>*]/)) {
        return "Cannot take  ( ) ~ ` < > * in input";
      }

      return true;
    },
    validateValue(val) {
      if (!val || !val.length) {
        return "Value can't be left empty";
      }
      if (val.match(/[()~`<>*]/)) {
        return "Cannot take  ( ) ~ ` < > * in input";
      }
      return true;
    },
  },
  props: ["fields","field","index","realtimeFeedback", "variableRef", "idx","parentComponent"],
  computed: {
    variables() {
      let toReturn = [];
      for (let field of this.fields) {
        toReturn.push(field.variable);
      }

      return toReturn;
    },
    getError() {//computes the props and then show the error message
      let isDuplicate = false;
      const validFailedRef = this.variableRef;
      if(validFailedRef) { //checks if its not undefined
        for(let i = 0; i< this.$children.length; i++) {
          if(this.$children[i] == validFailedRef) {//checks for the variable which have duplicate variable name
            isDuplicate = true;
            break;
          }
        }
        if(isDuplicate) {
          return "This variable already exists";
        }
      }
      return null;
    }
  },
};
</script>
<template>
  <v-container>
      <v-row justify="center">
        <v-col md="8" sm="12" lg="8" pa-2>
          <v-card>
            <v-card-text>
              <span class="title">Create a new admin</span>
              <v-divider></v-divider>
              <p class="mt-3 mb-4 subtitle-2">Please enter the details of the admin:</p>
              <v-form ref="form" v-model="valid" autocomplete="false">
                <v-text-field :rules="rules.email" label="Email Id of admin" v-model="email" outlined></v-text-field>
                <v-text-field :rules="rules.name" label="Name of admin" v-model="name" outlined></v-text-field>
                <v-text-field :rules="[...rules.username,...rules.mandatory]" label="Username" v-model="username" outlined></v-text-field>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="sendPasswordOnEmail"
                  label="Do you want to send password over email?"
                ></v-checkbox>
                <v-text-field
                  v-if="!sendPasswordOnEmail"
                  :rules="rules.password"
                  label="Password"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
                  @click:append="showPass = !showPass"
                  v-model="password"
                  outlined
                  autocomplete="new-password"
                ></v-text-field>
                <v-text-field
                  maxlength="10"
                  counter
                  :rules="rules.number"
                  label="Phone number"
                  type="text"
                  v-model="phone"
                  outlined
                ></v-text-field>
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="firstLogin"
                  label="First time password change needed for Admin?"
                ></v-checkbox>
                <div class="text-right">
                  <v-btn :disabled="!valid" color="primary" @click="createUser()">Create User</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="4" sm="12" lg="4" pa-2>
          <v-card>
            <v-card-text>
              <span class="title">All Admins</span>
              <v-divider></v-divider>
              <v-row v-if="allUsers.length != 0">
                <v-col sm="12" cols="12" md="12" xl="12" lg="12" pa-2 class="scrollAudit">
                  <v-card v-for="(item, i) in allUsers" :key="i" class="ma-2">
                    <v-card-text class="mb-0 pb-0">
                      <span class="title">{{ item.value.name }}</span>
                      <br />
                      <span class="body-2">{{ item.value.email }}</span>
                      <br />
                      <span class="body-2">@{{ item.value.username }}</span>
                    </v-card-text>
                    <v-card-actions>
                      <v-row>
                        <v-col sm="6" cols="6" md="6" xl="6" lg="6" >
                          <v-btn
                            small
                            outlined
                            block
                            color="signzy_blue"
                            @click="showResetDiag(item)"
                            >Reset Password</v-btn
                          >
                        </v-col>
                        <v-col sm="2" cols="2" md="2" xl="2" lg="2" >
                        </v-col>
                        <v-col sm="2" cols="2" md="2" xl="2" lg="2" style="paddingTop: 0.4rem">
                          <v-btn
                            text
                            icon
                            color="primary"
                            :disabled="disableEditAccess"
                            @click="showEditAdminDialog(item.value)"
                            ><v-icon small>fas fa-edit</v-icon></v-btn
                          >
                        </v-col>
                        <v-col sm="2" cols="2" md="2" xl="2" lg="2" style="paddingTop: 0.4rem">
                          <v-btn
                            text
                            icon
                            color="error"
                            style="paddingRight: 1rem"
                            @click="deleteAdmin(item.value.id)"
                            ><v-icon small>fas fa-trash</v-icon></v-btn
                          >
                        </v-col>
                        <!-- <v-col sm="6" cols="6" md="6" xl="6" lg="6" >
                          <v-btn
                            small
                            outlined
                            block
                            color="error"
                            @click="deleteAdmin(item.value.id)"
                            >Delete Admin</v-btn
                          >
                        </v-col> -->
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <div v-else class="scrollAudit">
                <p class="title">No users found!</p>
              </div>
              <v-dialog v-model="editAdminDialog" persistent max-width="500">
                <v-card>
                  <v-card-title>
                    <span class="title"
                      >Updating access for {{ getAdminName }}</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon color="red" @click="editAdminDialog = false">
                      <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-select
                      v-model="assignedFlows"
                      :items="allFlows"
                      label="Assign flows"
                      multiple
                      outlined
                    ></v-select>
                    <v-checkbox    
                      v-model="allowFlowCreation"
                      label="Allow subadmin to create new flows?"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="allowFlowDeletion"
                      label="Allow subadmin to delete existing flows?"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="allowEditingSubAdminAccess"
                      label="Allow subadmin to edit access of other subadmins?"
                    ></v-checkbox>
                    <div class="text-right">
                      <v-btn color="primary darken-1" dark @click="updateSubAdminAccess(currentAdminData.id)"
                        >Update</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog v-model="resetConfirmation" persistent max-width="500">
                <v-card>
                  <v-card-title>
                    <span class="title"
                      >Reset Password for {{ currentResetUser.name }} ?</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon color="red" @click="resetConfirmation = false">
                      <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="text-right">
                      <v-btn
                        color="primary darken-1"
                        dark
                        @click="resetPassword(currentResetUser)"
                        >Reset</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="askPhoneNumber" persistent max-width="500">
        <v-card>
          <v-card-title class="title mb-3">
            Please add your mobile number
          </v-card-title>
        <v-card-text>
          <v-text-field
            label="Mobile phone number"
            v-model="phone"
            type="text"
            counter
            :rules="phoneRules"
            maxlength="10"
            outlined
          ></v-text-field>
          <div class="text-right">
            <v-btn
              color="red darken-1"
              text
              @click="askPhoneNumber = false; phone = ''"
            >Cancel</v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="updatePhone()"
            >Update</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { end_points, constants } from "@/config";
import signzyAvatar from "@/Plugins/SignzyAvatarGenerator.js";
import enLib from "crypto-js/aes";

export default {
  data: () => ({
    valid: false,
    showPass:false,
    currentUserData: {},
    allUsers: [],
    name: "",
    assignManager: false,
    email: "",
    username: "",
    password: "",
    sendPasswordOnEmail: false,
    firstLogin: false,
    phone: "",
    assigned: {},
    currentResetUser: {},
    resetConfirmation: false,
    phoneRules: [
      v => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a proper mobile number!"
    ],
    askPhoneNumber: false,
    rules: {
      username: [
        v => !!v || "Field cannot be empty!",
        v => /^[a-zA-Z0-9_]+$/.test(v) || "Not a valid username!",
        v => v.length <= 35 || "Maximum 35 characters are allowed",
      ],
      mandatory: [v => /^.+$/.test(v) || "Field cannot be empty!"],
      mandatoryObj: [
        v => /^[0-9A-Za-z]{24}$/.test(v.id) || "Field cannot be empty!"
      ],
      number: [
        v => !!v || "Field cannot be empty!",
        v => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a valid mobile number!"
      ],
      name: [
        v => !!v || "Field cannot be empty!",
        v => v.length <= 70 || "Maximum 70 characters are allowed",
        v =>
        /^[a-zA-Z][a-zA-Z\s]*$/.test(v) ||
          "Only alphabets are allowed in this field"
      ],
      email: [
        v => !!v || "Field cannot be empty!",
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Not a proper email!"
      ],
      password: [
        v => !!v || "Field cannot be empty!",
        v =>  ((v.length >= 8) && /[a-z]/g.test(v) && /[A-Z]/g.test(v) && /[0-9]/g.test(v) && /[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(v))
          || "Password should be minimum 8 characters length including lowerCase, UpperCase, number and special character."
      ]
    },
    backopAgentId: {},
    role: "admin",
    allManagers: [],
    customerId: "",
    currentPersona: "",
    currentAdminData: {},
    allFlows: [],
    assignedFlows: [],
    editAdminDialog: false,
    allowFlowCreation: false,
    allowFlowDeletion: false,
    allowEditingSubAdminAccess: false,
    disableEditAccess: false,
  }),
  methods: {
    async updatePhone() {
      if (/^[6-9]{1}[0-9]{9}/.test(this.phone)) {
        let dataToPost = {
            phone: this.phone
          };
        dataToPost = enLib.encrypt(JSON.stringify(dataToPost), this.$store.getters.envValues.otpEncKey).toString();

        let res = await axiosInstance.patch(
          end_points.get_customer_data(this.currentUserData.id),
          {
            dataToPost
          }
        );
        if (res.data) {
          this.askPhoneNumber = false;
          eventBus.$emit("vueSnack", "Successfully updated phone number!");
        } else {
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      } else {
        eventBus.$emit("vueSnack", "Not a valid phone number!");
      }
    },
    async getAllUsers(customerId) {
      try {
        this.allUsers = (
          await axiosInstance.get(end_points.get_all_admins(customerId)) // get all users to come here
        ).data;
        this.allUsers = this.allUsers
          .filter(item => item.belongsTo == 0 && item.username != this.$store.getters.userData?.subAdminUserName && 
            item.email != this.$store.getters.userData?.subAdminEmail)
          .map(item => (item = { text: item.name, value: { ...item } }));
      } catch (error) {
        console.log(error);
        this.allUsers = [];
      }
    },
    showResetDiag(admin) {
      if (this.currentPersona === admin.value.id) {
        eventBus.$emit("vueSnack", "Cannot reset password of admin you are logged in from!");
      } else {
        this.currentResetUser = admin.value;
        this.resetConfirmation = true;
      }
    },
    showEditAdminDialog(subAdmin){
      this.currentAdminData = subAdmin;
      let tempFlows = ["global", "default", ...Object.keys(this.$store.getters.userData?.config?.multiflow ?? [])];

      if(this.$store.getters.subAdminAccessControl){
          this.allFlows = this.$store.getters.subAdminAccessControl.assignedFlows;

          //The below code removes the existing flows that is not present under the current subAdmin who is trying to assign new flows
          // this.assignedFlows = subAdmin.assignedFlows.filter(flowName => this.$store.getters.subAdminAccessControl.assignedFlows.includes(flowName));
      }else {
        this.allFlows = tempFlows;
      }

      this.assignedFlows = subAdmin.assignedFlows || [];
      this.allowFlowCreation= subAdmin.allowFlowCreation || false;
      this.allowFlowDeletion= subAdmin.allowFlowDeletion || false;
      this.allowEditingSubAdminAccess= subAdmin.allowEditingSubAdminAccess || false;
      this.editAdminDialog = true;
    },
    async updateSubAdminAccess(subAdminId) {
      let defaultJourneyType = "VKYC";

      if(this.assignedFlows.length > 0 && this.assignedFlows[0] != "global"){
        if(this.assignedFlows[0] == "default"){
          defaultJourneyType = this.$store.getters.userData?.config?.journeyType ?? "VKYC";
        } else {
          defaultJourneyType = this.$store.getters.userData?.config?.multiflow[this.assignedFlows[0]]?.journeyType ?? "VKYC";
        }
      }

      let payload = {
        assignedFlows: this.maintainFlowIndexes(this.assignedFlows), 
        allowFlowCreation: this.allowFlowCreation,
        allowFlowDeletion: this.allowFlowDeletion,
        allowEditingSubAdminAccess: this.allowEditingSubAdminAccess,
        defaultJourneyType
      }

      try {
        let response = await axiosInstance.patch(end_points.update_sub_admin_access(subAdminId), payload);
        if(response?.data?.status === "Success"){
          this.editAdminDialog = false;
          eventBus.$emit("vueSnack","Successfully updated!");
          let index = this.allUsers.findIndex(user => user.value.id == subAdminId);
          this.allUsers[index].value = { ...this.allUsers[index].value, ...payload }
        } else {
          eventBus.$emit("vueSnack","Failed to update!");
        }
      } catch(error){
        eventBus.$emit("vueSnack","Something went wrong while updating!");
        console.log("Error while trying to update subAdmin access", error)
      }

    },
    async resetPassword(user) {
      let options = {
        method: "POST",
        url: end_points.resetPass(this.customerId, user.id),
        body: {
          resetPassword: true,
        },
      };

      try {
        let resp = await axiosInstance(options);
        let data = resp.data;
        console.log("RESET STS", data.status);
        if (data.status == "success") {
          eventBus.$emit("vueSnack", "Password Reset mail sent successfully");
          this.resetConfirmation = false;
          this.currentResetUser = {};

        }
      } catch (err) {
        console.error("ERROR WHILE RESET PASS", err);
        eventBus.$emit("vueSnack", "Could not reset password");
        this.resetConfirmation = false;
        this.currentResetUser = {};
      }
    },
    async deleteAdmin(adminId) {
      if (this.currentPersona === adminId) {
        eventBus.$emit("vueSnack", "Cannot delete an Admin you are logged in from!");
      } else {
        try {
          if (confirm("Are you sure, you want to delete?")) {
            let res = await axiosInstance.post(
              end_points.delete_persona_admin( this.customerId, this.currentPersona ), {
                agentToDelete: adminId
              }
            );
            if (res.data.count && res.data.count === 1) {
              eventBus.$emit("vueSnack", "Successfully deleted!");
              await this.getAllUsers(this.customerId);
            } else {
              if(res.data.message){
                eventBus.$emit("vueSnack", res.data.message);
              }else{
                  eventBus.$emit("vueSnack", "Some Error happened in deleting user, try again later!");
              }
            }
            console.log("done calling!!");
            this.showDetails(this.backopAgentId);
          }
        } catch (error) {
          console.log(error);
          eventBus.$emit("vueSnack", "Something went wrong, please try again!");
        }
      }
    },
    maintainFlowIndexes(arr) {
      // Remove "global" and "default" if they exist in the array
      const globalIndex = arr.indexOf("global");
      if (globalIndex > -1) {
        arr.splice(globalIndex, 1);
      }

      const defaultIndex = arr.indexOf("default");
      if (defaultIndex > -1) {
        arr.splice(defaultIndex, 1);
      }

      // Add "default" into the start of the array if it exists
      if (defaultIndex > -1) {
        arr.unshift("default");
      }

      // Add "global" at the start of the array if it exists
      if (globalIndex > -1) {
        arr.unshift("global");
      }

      return arr;
    },
    getInitialAvatar(name) {
      return signzyAvatar({ name });
    },
    showDetails(manager) {
      console.log("done calling managers!!");
      this.backopAgentId = manager;
      let tmpManagers = this.allManagers.filter((item) => item.belongsTo == manager.value.id);
      console.log(tmpManagers)
      this.$set(this.backopAgentId, "allManagers", tmpManagers);
    },
    clearView() {
      this.username = "";
      this.password = "";
      this.email = "";
      this.name = "";
      this.phone = "";
      this.sendPasswordOnEmail = false;
      this.firstLogin = false;
      this.assigned = {};
      this.assignManager = false;
      this.role = "admin"
      this.$refs.form.reset();
    },
    async createUser() {
      if (this.name && this.email && this.username) {
        try {
          let res = await axiosInstance.post(
            end_points.create_agent(this.customerId),
            {
              name: this.name,
              email: this.email,
              username: this.username,
              phone: this.phone,
              password: this.password || "RandomOne",
              firstLogin: this.firstLogin,
              sendPasswordOnEmail: this.sendPasswordOnEmail,
              belongsTo: this.assignManager ? this.assigned.id : "",
              role: this.role
            }
          );
          console.log(res);
          if (res.data) {
            eventBus.$emit("vueSnack", "Successfully created a user!");
            this.clearView();
            let { token } = this.$store.getters.userData;
            await this.getAllUsers(this.customerId);
          } else {
            eventBus.$emit(
              "vueSnack",
              "Something went wrong, please try again!"
            );
          }
        } catch (error) {
          console.log(error.response)
          if(error.response.status == 400){
            eventBus.$emit('vueSnack', error.response.data.error.message);
          }else{
            let errRes = error.response.data.error.details;
            let errorToShow = [];
            for (let i in errRes.messages) {
              errorToShow.push(errRes.messages[i]);
            }
            eventBus.$emit("vueSnack", errorToShow.join(", "));
          }

        }
      } else {
        eventBus.$emit("vueSnack", "All the fields are requried!");
      }
    },
  },
  async created() {
    try {
      if (this.$store.getters.userData) {
        if (this.$store.getters.userData.userId) {
          this.currentPersona = this.$store.getters.userData.userId
        }
        let userId;
        if (this.$store.getters.userData.customerData) {
          userId = this.$store.getters.userData.customerData.id;
        } else {
          userId = this.$store.getters.userData.userId
        }
        let { token } = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = token;
        let resp = (
          await axiosInstance.get(end_points.get_customer_data(userId))
        ).data;
        this.currentUserData = {
          ...this.$store.getters.userData,
          ...resp
        };

        if ( this.currentUserData.customerData ) {
          this.customerId = this.currentUserData.customerData.id
        } else {
          this.customerId = this.currentUserData.userId
        }

        if (!this.currentUserData.phone) {
          //this.askPhoneNumber = true;
        }
        this.$store.commit('setUserData', this.currentUserData);
        await this.getAllUsers(userId);
        if(this.$store.getters.subAdminAccessControl){
          this.disableEditAccess = !this.$store.getters.subAdminAccessControl.allowEditingSubAdminAccess;
        } 
      } else {
        this.$router.push("/admin");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/admin");
    }
  },
  computed: {
    getAdminName(){
      if(this.currentAdminData.name){
        return this.currentAdminData.name.length < 19 ? this.currentAdminData.name : (this.currentAdminData.name.substring(0,14) + "...")
      }
    }
  },
};
</script>

<style scoped>
.scrollAudit {
  overflow: auto;
  height: 565px;
}
</style>

<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Conference Settings</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  class="mt-0 mb-0"
                  :disabled="!isScreenshare"
                  v-model="config.conference.isScreenshare"
                  label="Enable Screen Sharing?"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  class="mt-0 mb-0"
                  :disabled="!isRecording"
                  v-model="config.conference.isRecording"
                  label="Enable Session Recording?"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
let eventBus = window.eventBus;
let store = window.store;
export default {
  data: () => ({
    isScreenshare: false,
    isRecording: false
  }),
  methods: {
    submitForm() {
      this.config.conference.isScreenshare = this.isScreenshare
        ? this.config.conference.isScreenshare
        : false;
      this.config.conference.isRecording = this.isRecording
        ? this.config.conference.isRecording
        : false;
      eventBus.$emit("updateConfig", ["conference"]);
    }
  },
  created() {
    this.isScreenshare =
      this.$store.getters.userData.isScreenshare || false;
    this.isRecording =
      this.$store.getters.userData.isRecording || false;
  },
  props: ["config"]
};
</script>
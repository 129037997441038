<template>
  <v-container>
    <p class="headline">Completed Audit Admins</p>
    <v-row v-if="agents.length!=0">
      <v-col sm="12" cols="12" md="5" xl="4" lg="4" pa-2 class="scrollAudit">
        <v-card v-for="(item,i) in agents" :key="i" class="ma-2">
          <v-card-text>
            <div class="float-right">
              <v-btn icon color="signzy_blue" @click="showDetails(item)">
                <v-icon>fas fa-chevron-circle-right</v-icon>
              </v-btn>
            </div>
            <span class="title">{{item.name}}</span>
            <br />
            <span class="body-2">{{item.email}}</span>
            <br />
            <span class="body-2">@{{item.username}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" cols="12" md="7" xl="8" lg="8" pa-2>
        <BackopsCalls
          :isCallListVisible="isCallListVisible"
          :agentId="backopAgentId"
          :customerId="currentUserData.id"
          :status="status"
        />
      </v-col>
    </v-row>
    <div class="text-center" v-if="showLoader">
      <div class="bar">
        <i class="sphere"></i>
      </div>
    </div>
    <div v-else>
      <p class="title">No VCIP admins found!</p>
    </div>
    
  </v-container>
</template>

<script>
import { end_points } from "@/config";
import BackopsCalls from "./BackopsCalls.vue";
export default {
  data: () => ({
    agents: [],
    backopAgentId: "",
    isCallListVisible: true,
    currentUserData: {},
    status: "completed",
    customerId: "",
    showLoader: false
  }),
  components: {
    BackopsCalls
  },
  methods: {
    showDetails(agent) {
      this.backopAgentId = agent._id;
    },
    async getCompletedBackopUsers() {
      this.showLoader = true;
      try {
        let res = await axiosInstance.get(
          end_points.audited_backops_customer(
            this.customerId,
            "completed"
          )
        );
        if (res.data) {
          this.agents = res.data;
          this.showLoader = false;
        } else {
          this.showLoader = false;
          eventBus.$emit("vueSnack", "No Pending audits!");
        }
      } catch (error) {
        this.showLoader = false;
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    }
  },
  beforeDestroy() {
    eventBus.$off("loadCompletedAudit");
  },
  created() {
    this.currentUserData = this.$store.getters.userData;
    if ( this.currentUserData.customerData ) {
      this.customerId = this.currentUserData.customerData.id
    } else {
      this.customerId = this.currentUserData.userId
    }
    this.getCompletedBackopUsers();
    eventBus.$on("loadCompletedAudit", () => {
      this.backopAgentId = "";
      this.isCallListVisible = true;
      this.getCompletedBackopUsers();
    });
  }
};
</script>

<style scoped>
.scrollAudit {
  overflow: scroll;
  height: 630px;
}
.bar {
	/* Size and position */
    font-size: 20px; /* 1em */
    width: 10em;
    height: 1em;
    position: relative;
    margin: 100px auto;

    /* Styles */
    border-radius: .5em; /* Height/2 */
    background: white; /* Fallback */
    background: rgba(255,255,255,0.6);
    box-shadow: 
        0 0 0 .05em rgba(100,100,100,0.075), /* Subtle border */
        0 0 0 .25em rgba(0,0,0,0.1),		   /* Outter border */
        inset 0 .1em .05em rgba(0,0,0,0.1),   /* Inset shadow */
        0 .05em rgba(255,255,255,0.7);	   /* Slight reflection */
}
.bar:after {
	/* Content and position */
    content: "Please wait while records being fetched.";
    position: absolute;
    left: 5%;
    top: 150%;

    /* Font styles */
    font-family: 'Carrois Gothic', sans-serif;
    font-size: 14px;
    color: #555;
    text-shadow: 0 .05em rgba(255,255,255,0.7);
}
.sphere {
    /* Size */
    display: block;
    width: 1em;
    height: 100%;
    
    /* Styles */
    border-radius: 50%;
    background: linear-gradient(#eee, #ddd);
    box-shadow:
        inset 0 .15em .1em rgba(255,255,255,0.3), /* Top light */
        inset 0 -.1em .15em rgba(0,0,0,0.15),	   /* Bottom shadow */
        0 0 .25em rgba(0,0,0,0.3);			   /* Outter shadow */

    /* Animation */
    animation: move 1.75s ease-in-out infinite alternate;
}
</style>